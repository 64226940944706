@import "_font";
@import "_var";
@import "_mixin";
@import "_coms";
@import "_init";
@import "./pages/_header";
@import "./pages/_footer";
/* PAGES */
@import "./pages/comingsoon";
@import "./pages/home";
@import "./pages/contact";
@import "./pages/about";
@import "./pages/events";
@import "./pages/products";
@import "./pages/news";
@import "./pages/cart";
@import "./pages/checkout";
@import "./pages/search";
@import "./pages/account";
@import "./pages/meal-plan";
@import "./pages/account-workout";