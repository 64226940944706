body.page-template-template-home {
    header.header-page {
        .logo {
            img {
                @include tablet-up {
                    height: 90px;
                }
            }
        }
    }
}

header.header-page {
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 111;
    padding: 25px 0;
    transition: background 0.3s ease;
    &.fixed {
        background: #fff;
        padding: 25px 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        .logo img {
            @include tablet-up {
                height: 57.5px;
            }
        }
        .main-menu {
            top: 34px;
            &>li {
                &>.sub-menu {
                    top: calc(100% + 65px);
                }
            }
        }
        .mega-menu {
            top: calc(100% + 30px);
        }
        .top-menu {
            margin-top: 0;
        }
    }
    .logo {
        float: left;
        z-index: 100;
        position: relative;
        img {
            height: 58px;
            width: auto;
            transition: all 0.3s ease;
            @include tablet-up {
                height: 70px;
            }
            &.line {
                display: none;
                height: 58px;
            }
        }
    }
    .top-menu {
        float: right;
        background: $gradientver;
        transition: height 0.2s ease, width 0.4s ease;
        height: 58px;
        width: 58px;
        overflow: hidden;
        position: relative;
        z-index: 100;
        transition: margin 0.3s ease;
        @include mobile-up {
            margin-top: 10px;
        }
        &.expand {
            width: 315px;
            height: 470px;
            transition: width 0.2s ease, height 0.6s ease;
            .title-menu {
                visibility: visible;
                opacity: 1;
                transition: all 0.3s 0.1s ease;
            }
        }
    }
    .btn-menu-top {
        display: block;
        height: 58px;
        width: 58px;
        margin-left: auto;
        transition: all 0.4s ease;
        overflow: hidden;
        position: relative;
        background: #fff;
        border: 3px solid #49286F;
        &:before {
            content: "";
            background: $gradient;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            opacity: 0;
            transition: all 0.3s ease;
        }
        span {
            width: 17px;
            height: 2px;
            background: $purple;
            display: block;
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background: $purple;
            }
            &:before {
                top: -5px;
            }
            &:after {
                top: 5px;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
            span,
            span:before,
            span:after {
                background: #fff;
                transition: all 0.3s ease;
            }
        }
        &.expand {
            border-color: transparent;
            background: transparent;
            &:before {
                opacity: 0;
            }
            span,
            span:before,
            span:after {
                background: #fff;
            }
            span {
                background: transparent;
            }
            span:before {
                top: 50%;
                transform: scale(1.4) rotate(45deg);
            }
            span:after {
                top: 50%;
                transform: scale(1.4) rotate(-45deg);
            }
        }
    }
}
.free-shipping-bar {
    padding: 12px 0;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 14px;
    color: #F2F2F2;
    letter-spacing: 1px;
    text-align: center;
    white-space: nowrap;
    &.no-margin {
        margin-bottom: 0;
    }
    @include mobile-up {
        margin-bottom: 40px;
    }
}
.main-menu {
    list-style: none;
    z-index: 9;
    position: absolute;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    @include mobile-up {
        left: 200px;
        top: 45px;
    }
    @include mobile-down {
        right: 80px;
        top: 45px;
    }
    @include desktop-up {
        left: 22vw;
        top: 55px;
    }
    &>li {
        margin-right: 35px;
        &>a {
            //color: #999990;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            color: $black;
            font-family: $font-Pop;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            position: relative;
            padding-bottom: 30px;
            z-index: 1;
            &:before {
                content: "";
                position: absolute;
                top: 7px;
                height: 8px;
                width: 8px;
                left: -15px;
                z-index: 1;
                background: $purple;
                border-radius: 50%;
                transform: scale(0) translate3d(0, 0, 0);
                transition: transform 0.4s ease;
            }
        }
        &.active a,
        &.current_page_parent a,
        &.current_page_item a,
        &.current-menu-ancestor a,
        a.active,
        &:hover a {
            &:before {
                transform: scale(1) translate3d(0, 0, 0);
            }
        }
        &:hover {
            @include mobile-up {
                .mega-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
                &>.sub-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
        
        @include mobile-down {
            &:not(.shop) {
                display: none;
            }
        }
        &>.sub-menu {
            min-height: auto;
            position: absolute;
            top: calc(100% + 50px);
            padding-left: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, -5px, 0);
            transition: all 0.4s ease;
            padding: 35px 0;
            list-style-type: none;
            display: flex;
            min-width: 350px;
            @include tablet {
                left: -12vw;
            }
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 1px;
                left: -500%;
                width: 500vw;
                height: 100%;
                background: #fff;
                border-top: 1px solid rgba($purple, 0.2);
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
                z-index: -1;
                @include tablet {
                    left: -250%;
                }
            }
            &:before {
                top: 0px;
            }
            ul {
                list-style: none;
                padding-left: 0;
            }
            &>li {
                // min-width: 45%;
                // margin-right: 10px;
                min-width: auto;
                margin-right: 3rem;
                @include tablet-down {
                    min-width: 35%;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    color: $back-2;
                    font-family: $font-Pop;
                    text-decoration: none;
                    margin-bottom: 10px;
                    position: relative;
                    &:before {

                        content: "";
                        position: absolute;
                        width: 0;
                        height: 2px;
                        background: $purple;
                        right: 105%;
                        top: 7px;
                        transition: width 0.3s ease;
                    }
                    &:hover {
                        color: $purple;
                        &:before {
                            width: 12px;
                        }
                    }
                }
                &>a {
                    font-family: $font-Pop;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.mega-menu {
    min-height: 300px;
    position: absolute;
    top: calc(100% + 50px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -5px, 0);
    transition: all 0.4s ease;
    @include tablet {
        left: -12vw;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: -200%;
        width: 200vw;
        height: 100%;
        background: #fff;
        border-top: 1px solid rgba($purple, 0.2);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        z-index: -1;
        @include tablet {
            left: -100%;
        }
    }
    &:before {
        top: -90%;
    }
    ul {
        list-style: none;
        padding-left: 0;
    }
    ul.mega-ul {
        display: flex;
        flex-flow: row;
        margin-top: 35px;
        &>li {
            min-width: 185px;
            a {
                display: block;
                font-family: $font-Rr;
                color: $black;
                text-decoration: none;
                margin-bottom: 10px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 2px;
                    background: $purple;
                    right: 105%;
                    top: 7px;
                    transition: width 0.3s ease;
                }
                &:hover {
                    color: $purple;
                    &:before {
                        width: 12px;
                    }
                }
            }
            &>a {
                font-family: $font-br;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}
// PANEL-right
.backdrop-core {
    &.panel-main {
        z-index: 221;
    }
}
.panel-right {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    z-index: 222;
    width: 100%;
    padding: 20px 20px;
    transition: right 0.5s ease;
    color: #fff;
    @include small-mobile-up {
        width: 400px;
        padding: 40px 40px 20px;
    }
    &.expand {
        right: 0;
    }
    .panel-title {
        font-weight: 700;
        font-size: 24px;
        color: $text-gray;
        position: relative;
        padding-right: 30px;
    }
    .panel-scroll {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        @include small-mobile-up {
            top: 70px;
        }
        &>.scrollbar-macosx {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 45px);
            overflow-x: hidden;
            overflow-y: auto;
        }
        .cart-empty {
            .container {
                border-bottom: none;
                padding-bottom: 0px;
            }
            .img-bag {
                margin-right: 0;
                height: 165px;
                img {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .cart-empty-text {
                margin-left: 15px;
                padding-top: 10px;
                p {
                    margin-bottom: 20px;
                }
                .btn-edit {
                    &.white {
                        margin-top: 0px;
                    }
                }
            }
        }
        .cart-empty-holder {
            align-items: center;
        }
    }
    .copyright {
        position: absolute;
        bottom: 15px;
        text-transform: uppercase;
        color: #F2F2F2;
        opacity: 0.5;
        font-size: 14px;
    }
}
.coll-item {
    padding: 20px 0 0;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    &:not(:last-child) {
        border-bottom: 1px solid rgba($grey, 0.1);
    }
    &.no-mg {
        .coll-wrap {
            margin-bottom: 0;
        }
    }
    @include small-mobile-up {
        margin-left: 40px;
        margin-right: 40px;
    }
    &.for-user[data-show="true"] {
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            top: 1px;
            background: $gradientver;
            z-index: -1;
            right: -20px;
            left: -20px;
            @include small-mobile-up {
                left: -40px;
                right: -40px;
            }
        }
    }
    a[data-toggle="collapse"],
    .like-collapse {
        text-decoration: none;
        display: block;
        position: relative;
        display: flex;
        &:not(.like-collapse):after {
            content: "";
            position: absolute;
            right: 0;
            top: 7px;
            width: 15px;
            height: 8px;
            background: transparent url("../img/arrow-up-white.svg") no-repeat center / auto 100%;
            transition: transform 0.3s ease;
            display: none !important;
        }
        &.collapsed {
            &:after {
                transform: rotate(-180deg);
            }
        }
        .icon-col {
            width: 40px;
        }
        .fa {
            font-size: 22px;
            color: #fff;
        }
        .img-drop {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            img {
                border-radius: 50%;
            }
        }
        a {
            color: #fff;
        }
    }
    .title {
        font-family: $font-br;
        font-weight: 500;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 20px;
        .bag {
            font-weight: 600;
            color: $grey;
            margin-left: 10px;
        }
    }
    .coll-wrap {
        margin-bottom: 20px;
        &.bg-gradient {
            margin: 0 -40px 20px;
            padding: 0 40px;
        }
        .date {
            font-weight: 600;
            font-weight: $font-Rr;
        }
        .other-link {
            font-family: $font-Rr;
            font-weight: 600;
            font-size: 12px;
            color: $text-gray;
            letter-spacing: 0.3px;
            line-height: 18px;
            text-transform: uppercase;
            margin-top: 20px;
            margin-left: 20px;
            display: block;
        }
        ul {
            margin-bottom: 0;
            list-style: none;
            padding-left: 0;
            &.for-user {
                padding-left: 40px;
            }
            li {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
            a {
                font-family: $font-br;
                font-weight: 500;
                font-size: 16px;
                color: $text-gray;
                letter-spacing: 0.2px;
            }
        }
    }
    .text-link {
        font-family: $font-Rr;
        font-weight: 600;
        font-size: 12px;
        color: $text-gray;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-transform: uppercase;
        display: block;
        @include small-mobile {
            margin-bottom: 8px;
        }
    }
}
.go-cart-row {
    background-image: linear-gradient(+135deg, #21285D 0%, #49286F 100%);
    margin-bottom: -20px;
    text-align: center;
    padding: 15px 20px;
    margin-right: -20px;
    margin-left: -20px;
    @include small-mobile-up {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 15px 40px;
        margin-right: -40px;
        margin-left: -40px;
    }
    .btn-edit {
        font-size: 12px;
        min-width: 150px;
        margin-top: 0;
    }
}
ul.for-cart {
    &.cart-ajax-section {
        li:first-child {
            padding-top: 0;
        }
    }
    .wrap-mini {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        .info {
            .price, .price-edit {
                .woocommerce-Price-amount.amount {
                    color: $white;
                }
            }
        }
    }
    .wrap-large {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        .img {
            width: 92px;
            height: 92px;
        }
        .price-row {
            padding-top: 30px;
            span {
                display: block;
                margin-left: 0;
            }
        }
    }
    .img {
        width: 46px;
        height: 46px;
        background: #fff;
        margin-right: 15px;
        display: block;
    }
    .price {
        font-weight: 500;
    }
    .price-row {
        padding-top: 5px;
    }
    .size,
    .quantity {
        font-weight: 500;
        font-size: 14px;
        color: $grey;
    }
    .quantity {
        margin-left: 20px;
    }
    a.remove-it,
    a.edit-it {
        position: absolute;
        top: 20px;
        right: 0;
        font-weight: 500;
        font-size: 12px;
        color: $grey;
        text-decoration: underline;
    }
    a.edit-it {
        top: auto;
        bottom: 15px;
    }
    a.name-it {
        display: block;
        padding-right: 50px;
    }
    li {
        padding-top: 15px;
        padding-bottom: 5px;
        font-family: $font-br;
        position: relative;
        &:not(:first-child) {
            border-top: 1px solid rgba($grey, 0.1);
        }
    }
}
.close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 3px;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background: $text-gray;
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
.modal-edit-user {
    @include mobile-down {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
    }
    .close {
        text-decoration: none;
        color: #F2F2F2;
        position: relative;
        top: 5px;
        width: 20px;
        height: 20px;
        display: block;
        margin: 0;
        padding: 0;
        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 2px;
            background: #F2F2F2;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    .modal-small {
        max-width: 400px;
    }
    .modal-header {
        border: 0;
        .modal-title {
            font-size: 24px;
            font-weight: 700;
            color: #F2F2F2;
            position: relative;
            img {
                position: absolute;
                left: 0;
            }
            span {
                padding-left: 80px;
                display: block;
            }
        }
    }
    .modal-body {
        .wrap-form {
            .group-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                flex-wrap: wrap;
                input {
                    border-bottom: 1px solid #A7AAAA !important;
                }
                .gap-50 {
                    @include small-mobile-up {
                        width: 50%;
                    }
                    .forgot {
                        font: 600 10px / 18px $font-Rr;
                        letter-spacing: 0.25px;
                        color: #A7AAAA;
                    }
                }
                &.pass {
                    position: relative;
                    input[type="password"] {
                        padding-right: 30px;
                    }
                    i.btn-eye {
                        position: absolute;
                        right: 0;
                        background: url(../img/unview.svg) no-repeat center/ auto 100%;
                        width: 30px;
                        height: 20px;
                        cursor: pointer;
                        display: inline-block;
                        z-index: 9;
                        top: 10px;
                    }
                    i.btn-eye.showed {
                        background: url(../img/view.svg) no-repeat center/ auto 100%;
                    }
                }
                &.bd-non {
                    border-bottom: 0;
                }
                input[type="text"],
                input[type="password"],
                input[type="tel"],
                input[type="email"],
                select {
                    @include reset-input;
                    @include reset-autofill;
                    width: 100%;
                    background-color: transparent;
                    padding: 10px 0;
                    font: 600 12px/ 18px $font-Rr;
                    letter-spacing: 0.3px;
                    color: #F2F2F2;
                    text-overflow: ellipsis;
                    @include placeholder {
                        transition: opacity 0.2s ease;
                        text-transform: uppercase;
                        color: #F2F2F2;
                    }
                    &[disabled] {
                        color: #F2F2F2 !important;
                    }
                    &:focus {
                        @include placeholder {
                            opacity: 0.35;
                        }
                    }
                    @include small-mobile {
                        font-size: 16px;
                    }
                }
                input[type="checkbox"] {
                    height: 0;
                    width: 0;
                    visibility: hidden;
                    &:checked+label {
                        &:after {
                            transform: scale(1) rotate(125deg);
                            opacity: 1;
                        }
                    }
                    &+label {
                        color: #F2F2F2;
                        padding-left: 25px;
                        margin-bottom: 0;
                        position: relative;
                        cursor: pointer;
                        font: 600 12px/ normal $font-Rr;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: -2px;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #FFF;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            top: -4px;
                            left: 2px;
                            transform: scale(1.3) rotate(125deg);
                            width: 18px;
                            height: 8px;
                            border-top: 1px solid #fff;
                            border-right: 1px solid #fff;
                            transition: all .3s ease;
                            opacity: 0;
                        }
                    }
                }
            }
            .group-label {
                margin-top: 15px;
                margin-bottom: 25px;
                p {
                    font-family: $font-br;
                    font-weight: 400;
                    font-size: 12px;
                    color: #f2f2f2;
                    a {
                        color: #f2f2f2;
                        font-weight: 700;
                    }
                }
            }
            .col-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                .col-half {
                    width: 100%;
                    @include mobile-up {
                        width: 48%;
                    }
                    .date {
                        color: #A7AAAA;
                        font-size: 10px;
                        position: absolute;
                        right: 1rem;
                    }
                }
            }
            .text-or {
                text-align: center;
                color: #F2F2F2;
                margin: 20px 0;
            }
            .group-btn {
                button[type="submit"] {
                    @include reset-input;
                    background-color: #F2F2F2;
                    width: 100%;
                    color: #4C3274;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.3px;
                    text-align: center;
                    padding: 19px 0;
                    cursor: pointer;
                    transition: opacity 0.5s ease;
                    &:hover {
                        opacity: 0.7;
                    }
                }
                .btn-fb {
                    display: block;
                    width: 100%;
                    padding: 19px 0;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.3px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #F2F2F2;
                    border: 2px solid #F2F2F2;
                    margin-top: 15px;
                    position: relative;
                    transition: opacity 0.5s ease;
                    &:before {
                        content: "\f09a";
                        font-family: FontAwesome;
                        font-size: 20px;
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .register {
                text-align: center;
                font-weight: 600;
                font-size: 12px;
                text-align: center;
                color: #F2F2F2;
                margin-top: 60px;
                span {
                    display: block;
                }
                a {
                    margin-top: 10px;
                    color: #F2F2F2;
                    display: inline-block;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
            .text-welcome {
                color: #F2F2F2;
                letter-spacing: 0.2px;
                margin-bottom: 70px;
                line-height: 24px;
            }
            .datetime {
                display: block;
                position: relative;
                .input-group-append {
                    button {
                        @include reset-input;
                        height: 100%;
                        width: 100%;
                        background-color: transparent;
                        position: absolute;
                        left: 0;
                        i {
                            color: #aeaeae;
                            right: 0;
                            left: auto;
                        }
                    }
                }
                i {
                    color: #aeaeae;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 10px 0;
                    cursor: pointer;
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
    }
    .modal-dialog {
        transform: none !important;
    }
}
.form-search-mini {
    position: relative;
    input {
        @include reset-input;
        background: transparent;
        font-family: $font-br;
        color: #FFFFFF;
        padding-left: 40px;
        font-weight: 500;
        @include placeholder {
            color: #fff;
        }
    }
    button[type="submit"] {
        @include reset-input;
        background: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.wrap-form .select2-container {
    width: 100% !important;
    .select2-selection--single {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        border: 0 !important;
        outline: 0;
        height: auto;
        padding: 10px 0;
        .select2-selection__rendered {
            font-size: 12px;
            color: #F2F2F2;
            line-height: 1rem;
            padding: 0;
        }
        .select2-selection__arrow {
            top: 15px;
            height: 8px;
            width: 15px;
        }
    }
}
.select2-dropdown {
    border: 0;
    border-radius: 0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-results__option[aria-selected] {
    font-size: 12px;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background: #F2F2F2;
    color: #000;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #CCC;
    color: #000;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: 0;
    background: transparent url(../img/arrow-up-white.svg) no-repeat center / auto 100%;
    width: 15px;
    height: 8px;
    transform: rotate(-180deg);
    left: 0;
    margin-left: 0;
    margin-top: 0;
    top: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transform: rotate(0);
}
/* SPECIAL EDIT FOR PRODUCT-PAGE */
.products-page {
    header.header-page {
        background: #fff;
        @include mobile-down {
            padding: 15px 0;
        }
        .logo {
            margin-top: 5px;
            @include mobile-down {
                margin-top: 8px;
            }
            img.line {
                display: block;
            }
            img {
                @include mobile-down {
                    height: 43px;
                }
            }
        }
        &.fixed {
            .logo {
                @include mobile-up {
                    margin-top: 0;
                }
            }
        }
    }
    #wrap-page {
        padding-top: 88px;
        @include mobile-up {
            padding-top: 118px;
        }
    }
    .main-menu {
        @include mobile-up {
            left: 300px;
            top: 60px;
        }
        @include mobile-down {
            top: 34px;
        }
        @include desktop-up {
            left: 30vw;
        }
    }
}
.account-page {
    font-family: $font-Pop;
    #wrap-page {
        padding-top: 88px;
        @include mobile-up {
            padding-top: 118px;
        }
    }
    .free-shipping-bar {
        margin-bottom: 0;
    }
}
.searchs-page {
    header.header-page {
        background: #fff;
        @include mobile-down {
            padding: 15px 0;
        }
        .logo {
            margin-top: 5px;
            @include mobile-down {
                margin-top: 8px;
            }
            img.normal {
                display: none;
            }
            img.line {
                display: block;
            }
            img {
                @include mobile-down {
                    height: 43px;
                }
            }
        }
        &.fixed {
            .logo {
                @include mobile-up {
                    margin-top: 0;
                }
            }
        }
    }
    #wrap-page {
        padding-top: 138px;
        @include mobile-up {
            padding-top: 168px;
        }
    }
    .main-menu {
        @include mobile-up {
            left: 300px;
            top: 60px;
        }
        @include mobile-down {
            top: 34px;
        }
        @include desktop-up {
            left: 30vw;
        }
    }
}
.to-menu-panel {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: #fff;
    padding-left: 25px;
    vertical-align: middle;
    background: transparent url(../img/icon-slider-prev-white.svg) left center/8px 16px no-repeat;
    &:hover {
        text-decoration: none;
        opacity: .7;
        color: #fff;
    }
}
label.error {
    color: red !important;
    font-size: 12px;
    margin-top: 10px;
}

#menu-item-523 {
   &.single-event-menu {
       a {
           &:before {
               content: "";
               position: absolute;
               display: block;
               top: 7px;
               height: 8px;
               width: 8px;
               left: -15px;
               z-index: 1;
               background: #49286f;
               border-radius: 50%;
               -webkit-transition: -webkit-transform .4s ease;
               transition: -webkit-transform .4s ease;
               transition: transform .4s ease;
               transition: transform .4s ease,-webkit-transform .4s ease;
               -webkit-transform: scale(1) translate3d(0,0,0) !important;
               transform: scale(1) translate3d(0,0,0) !important;
           }
       }
   }
}
// Popup confirm
.modal-alert {
   .close {
       @include reset-input;
       cursor: pointer;
       font-size: 20px;
       font-weight: 700;
       background: none !important;
   }
   .modal-body {
       h6 {
           font-size: 16px;
           font-weight: 400;
           line-height: 1.5em;
       }
       a {
           text-decoration: none;
       }
       .btn-transparent {
           margin: 20px 10px 5px;
           height: 50px;
           min-width: 200px;
           font-size: 14px;
           text-align: center;
           border: 0;
           border-radius: 0;
           outline: 0;
           vertical-align: top;
           display: inline-flex;
           height: 60px;
           min-width: 290px;
           color: #000;
           background: 0 0;
           border: 1px solid #000;
           align-items: center;
           justify-content: center;
           text-transform: uppercase;
           letter-spacing: 2px;
           padding: 5px 12px;
           cursor: pointer;
       }
       .btn-blueblack {
           margin: 20px 10px 5px;
           height: 50px;
           min-width: 200px;
           font-size: 14px;
           text-align: center;
           background-color: $blueblack;
           border: 0;
           border-radius: 0;
           outline: 0;
           vertical-align: top;
           display: inline-flex;
           height: 60px;
           min-width: 290px;
           align-items: center;
           justify-content: center;
           text-transform: uppercase;
           letter-spacing: 2px;
           padding: 5px 12px;
           cursor: pointer;
           color: #fff;
       }
    }
}
.main-menu {
    &>li {
        .sub-menu {
            &>li {
                &.active {
                    &>a {
                        &:before {
                            content: "";
                            position: absolute;
                            display: block;
                            top: 7px;
                            height: 8px !important;
                            width: 8px !important;
                            left: -15px;
                            z-index: 1;
                            background: #49286f;
                            border-radius: 50%;
                            -webkit-transition: -webkit-transform .4s ease;
                            transition: -webkit-transform .4s ease;
                            transition: transform .4s ease;
                            transition: transform .4s ease,-webkit-transform .4s ease;
                            -webkit-transform: scale(1) translate3d(0,0,0) !important;
                            transform: scale(1) translate3d(0,0,0) !important;
                        }
                    }
                }
                
            }
        }
    }
}
.header-white-bg {
    background: $white;
}
/* CSS header child-page hover background and height */

header.header-page {
    &.page-child {
        &.fixed {
            .main-menu {
                &>li {
                    &>.sub-menu {
                        top: calc(100% + 40px);
                    }
                }
            }
        }
        .main-menu {    
            &>li {
                &>.sub-menu {
                    top: calc(100% + 40px);
                }
            }
        }
    }
}
