.result-found {
    font-family: $font-Rr;
    font-size: 28px;
    color: $purple;
    margin-bottom: 1rem;
    text-transform: uppercase;
    @include small-mobile-up {
        font-size: 36px;
    }
    @include tablet-up {
        font-size: 48px;
        margin-bottom: 1.5rem;
    }
}
.sub-title {
    font-size: 45px;
    @include small-mobile-up {
        font-size: 32px;
    }
}

.end-result {
    color: $grey;
    font: 500 24px/28px $font-Rr;
    @include small-mobile {
        font-size: 20px;
    }
}

.search-events {
    .featured-products,
    .list-last-news {
        padding-bottom: 100px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    .line-gray {
        margin-top: 50px;
    }
    .btn-edit {
        z-index: 2;
        position: relative;
    }
}

.line-gray {
    position: relative;
    width: 100%;
    &:before {
        content: '';
        width: 350px;
        background: #fff;
        height: 50px;
        transform: translate3d(-50%, -50%, 0);
        top: 50%;
        left: 50%;
        position: absolute;
        z-index: 1;
        @include small-mobile {
            width: 300px;
        }
    }
    &:after {
        content: '';
        background: $text-gray;
        width: 100%;
        top: 50%;
        left: 0;
        height: 1px;
        position: absolute;
        transform: translate3d(0, -50%, 0);
        z-index: 0;
    }
}

.row-product-list {
    @include small-mobile-up {
        @include space-col(20px);
    }
    &>[class*="col"] {
        margin-bottom: 25px;
    }
}