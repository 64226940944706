.container-workout {
    padding: 0 15px;
    @include tablet-up {
        padding-left: 40px;
    }
    @include desktop-up {
        padding-left: 90px;
    }
}
.banner-footer {
    &.no-margin {
        margin-top: 0;
    }
}
.header-workout {
    box-shadow: 0 4px 8px rgba(#ececec, .08);
    padding: 15px 0;
    transition: all .3s ease;
    background: #fff;
    &.fixed {
        position: fixed;
        top: 88px;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    @include tablet-up {
        padding: 38px 0;
    }
    .previous-page {
        max-width: 100%;
        flex: 0 0 100%;
        @include tablet-up {
            max-width: 40%;
            flex: 0 0 40%;
            padding-right: 15px;
            margin-bottom: 0;
        }
    }
    .week-list {
        max-width: 100%;
        flex: 0 0 100%;
        @include tablet-up {
            max-width: 540px;
            flex: 0 0 540px;
        }
    }
}
.wrap-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.previous-btn {
    padding-left: 64px;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    background: transparent url('../img/btn-back-gray.svg') left top/40px 40px no-repeat;
    min-height: 35px;
    transition: all .3s ease;
    @include small-mobile-up {
        background-size: 48px 48px;
        padding-left: 72px;
        min-height: 48px;
    }
    &:hover {
        text-decoration: none;
        background-position: left 5px top;
    }
    .orange-text {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: #F5A623;
        font-family: $font-Pop;
        @include small-mobile-up {
            font-size: 24px;
        }
    }
    .gray-text {
        color: $gray-acc;
        font-size: 14px;
        font-family: $font-Pop;
        font-weight: 500;
    }
}
.week-list {
    ul {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        overflow-x: auto;
        padding: 7px 0 !important;
        @include tablet-up {
            justify-content: flex-end;
        }
        &>li {
            margin-right: 35px;
            @include tablet-up {
                margin-right: 20px;
            }
            a {
                font: 600 16px/1.4375em $font-Pop;
                color: rgba(#999, .25);
                transition: all .3s ease;
                white-space: nowrap;
                &:hover,
                &.active {
                    color: $orange;
                    text-decoration: none;
                }
            }
        }
    }
    .scrollbar-macosx>.scroll-element.scroll-x .scroll-bar {
        height: 3px;
        top: -3px;
        background: rgba(#6C6E71, .3);
    }
}
.workout-content {
    overflow-y: auto;
    overflow-x: hidden;
    .wrap-card-row {
        &.none {
            display: none;
            &.active {
                display: flex;
            }
        }
    }
}
.card-wrapper {
    display: flex;
    padding: 5px 0 15px;
    @include make-row(8px);
    @include mobile-up {
        // width: 724px;
        @include make-row(16px);
    }
    .card-workout {
        width: 230px;
        //height: 120px;
        margin-bottom: 15px;
        overflow: hidden;
        opacity: 0.3;
        transition: height .5s ease;
        background: $white;
        @include mobile-up {
            margin: 0 25px;
            margin-bottom: 40px;
        }
        @include mobile-down {
            margin-left: 15px;
        }
        &:nth-child(2n) {
            .img-drop {
                box-shadow: 0 4px 12px rgba(#3023AE, .2);
            }
        }
        &:nth-child(3n) {
            .img-drop {
                box-shadow: 0 4px 12px rgba(#B4EC51, .2);
            }
        }
        &.show-full {
            //height: 330px;
            opacity: 1;
        }
    }
}
.card-workout {
    max-width: 230px;
    width: 100%;
    position: relative;
    .img-drop {
        padding-top: 152/250*100%;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(to bottom, rgba(#000, .5), rgba(#000, 0));
            z-index: 1;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, .75));
            z-index: 1;
        }
        img {
            object-position: top;
        }
    }
    .cart-workout-desc-wrap {
        padding: 15px;
        display: flex;
        flex-direction: column;
        .time-distance {
            font-family: $font-Pop;
            font-size: 12px;
            font-weight: 700;
            color: $text-workout;
        }
        .title-card {
            width: 100%;
            font-family: $font-Pop;
            line-height: 1.43em;
            color: $blueblack;
            font-size: 14px;
            z-index: 2;
            text-transform: uppercase;
            span {
                display: block;
                font-family: $font-Pop;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.4em;
                text-transform: initial;
            }
        }
        .workout-distance {
            font-family: $font-Go;
            color: #fff;
            font-size: 48px;
            line-height: 1.0625em;
            span {
                display: block;
                font-family: $font-Pop;
                font-size: 12px;
                font-weight: 300;
                line-height: 1.4em;
            }
        }
        .more-details {
            /* margin-bottom: 8px;
            font-size: 12px;
            font-family: $font-Pop;
            color: $black-acc;
            font-weight: 600;
            line-height: 1.43em;
            word-break: break-word; */
            color: $black-acc;
            font-family: $font-Pop;
            font-size: 12px;
            line-height: 18px;
            span {
                font-size: 12px;
                font-weight: 500;
                line-height: 1.4em;
                color: rgba(#fff, .56);
            }
        }
        .bottom-card {
            width: 100%;
            max-height: 50px;
            overflow-y: auto;
            overflow-x: hidden;
            z-index: 2;
            margin-bottom: 15px;
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #DDDDDD;
            }
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar,
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: #DDDDDD;
                }
            }
        }
        .btn-view-workout-wrap {
            display: block;
            text-align: right;
            margin-top: auto;
            .btn-view-workout {
                color: $white;
                font-family: 'Raleway';
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                background: linear-gradient(-135deg, #21285D 0%, #49286F 100%);
                padding: 5px 20px;
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
.owl-card {
    .owl-stage-outer {
        overflow: visible;
    }
    .owl-stage {
        @include mobile-up {
            padding-left: 0 !important;
        }
    }
    .owl-item {
        transition: all .3s ease;
        &:not(.active) {
            opacity: .2;
        }
        &.active {
            .card-wrapper {
                .card-workout {
                    //cursor: pointer;
                }
            }
        }
    }
}
.wrap-card-row {
    display: flex;
    @include mobile-up {
        display: block;
    }
    &:last-child {
        .content-card {
            padding-bottom: 20px;
            @include mobile-up {
                padding-bottom: 50px;
            }
        }
    }
    .nav-days {
        flex: 0 0 50px;
        max-width: 50px;
        background: #fff;
        padding-left: 15px;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 30px;
        flex-direction: column;
        display: inline-block;
        vertical-align: top;
        @include small-mobile-up {
            flex: 0 0 70px;
            max-width: 70px;
            padding-left: 27px;
        }
        @include mobile-up {
            flex: 0 0 auto;
            width: 90px;
            padding-bottom: 48px;
            float: left;
        }
        span {
            display: block;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            font-family: $font-Pop;
            color: #494949;
        }
    }
    .content-card {
        flex: auto;
        background: #F2F2F2;
        padding-top: 25px;
        padding-left: 20px;
        display: inline-block;
        @include mobile-up {
            padding-top: 40px;
            padding-left: 32px;
            width: calc(100% - 70px);
            float: left;
        }
        .card-wrapper-no-items {
            display: flex;
            padding: 0 25px;
            align-items: center;
            color: #ffff;
            font-size: 14px;
            background-color: #F5A623;
            width: 188px;
            height: 120px;
            text-transform: uppercase;
        }
    }
}
.week-list {
    &.week-list-workout {
        @include mobile-down {
            display: flex;
        }
    }
}
.wrap-header {
    &.wrap-header-workout {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
.previous-page {
    &.previous-workout {
        @include mobile-down {
            &:before {
                content: '';
                position: absolute;
                background-color: #fff;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 150px;
            }
        }
    }
}
.header-workout {
    &.header-workout-fixed {
        @include mobile-down {
            // background-color: $text-gray;
            .previous-btn {
                position: relative;
            }
        }
    }
}
.modal-workout-content {
    .btn-close-popup {
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        width: 18px;
        height: 18px;
        z-index: 9999;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 18px;
            width: 2px;
            background-color: #333;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    .workout-content-wrap {
        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9999;
            padding: 24px;
            border: 0;
            .close {
                background: transparent;
                border: 0;
                padding: 0;
                margin: 0;
            }
        }
        .modal-body {
            padding: 0;
            .img-drop {
                position: relative;
                z-index: 1;
                padding-top: 354/536*100%;
                background: red;
                img {
                    width: 100%;
                    object-position: top;
                }
                .workout-hour {
                    opacity: 0.6;
                    color: $white;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 30px;
                    position: absolute;
                    bottom: 55px;
                    left: 0;
                    width: 100%;
                }
                .workout-title {
                    color: $white;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 42px;
                    position: absolute;
                    bottom: 15px;
                    left: 0;
                    width: 100%;
                    @include mobile-up {
                        font-size: 28px;
                    }
                }
            }
        }
        .workout-description {
            padding: 25px 25px 10px 25px;
            p {
                color: $black-acc;
                font-family: 'Poppins';
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 15px;
            }
        }
    }
}