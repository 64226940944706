// CODE HERE
body {
    font: 500 16px / normal $font-Pop;
    letter-spacing: .3px;
    color: $black;
}
//SPACING//
.mb-30 {
    margin-bottom: 30px;
}
.icon {
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    &.spinner {
        width: 70px;
        height: 70px;
        background-image: url("../img/loading.svg");
    }
    &.cart {
        width: 24px;
        height: 24px;
        background-image: url("../img/icon-cart.svg");
    }
    &.event {
        width: 24px;
        height: 24px;
        background-image: url("../img/icon-event.svg");
    }
    &.news {
        width: 24px;
        height: 24px;
        background-image: url("../img/icon-news.svg");
    }
    &.search {
        width: 24px;
        height: 24px;
        background-image: url("../img/icon-search.svg");
    }
    &.user-alt {
        width: 24px;
        height: 24px;
        background-image: url("../img/icon-user-alt.svg");
    }
    &.arrow-r {
        width: 12px;
        height: 12px;
        background-image: url("../img/arrow-r.svg");
        margin-left: 7px;
    }
    &.printer {
        width: 24px;
        height: 24px;
        background-image: url("../img/print.svg");
    }
    &.email-letter {
        width: 24px;
        height: 16px;
        background-image: url("../img/mail.svg");
    }
    &.locker {
        width: 12px;
        height: 16px;
        background-image: url("../img/locker.svg");
    }
    &.locker-gray {
        width: 12px;
        height: 16px;
        background-image: url("../img/locker-gray.svg");
    }
    &.arrow-r-white {
        width: 12px;
        height: 14px;
        background-image: url("../img/arrow-r-white.svg");
        margin-left: 7px;
        vertical-align: text-bottom;
        transition: all .3s ease;
        &.no-ml {
            margin-left: 0;
        }
    }
    &.arrow-back {
        width: 12px;
        height: 19px;
        background-image: url("../img/chev-back.svg");
        margin-right: 5px;
        vertical-align: sub;
        transition: all .3s ease;
        position: relative;
        right: 0;
    }
    &.check-white {
        width: 22px;
        height: 22px;
        background-image: url("../img/check-white.svg");
        vertical-align: bottom;
        margin-right: 8px;
    }
    &.icon-info {
        width: 50px;
        height: 40px;
        background-image: url("../img/info-icon.svg");
    }
    &.icon-envelope {
        width: 22px;
        height: 13px;
        background-image: url("../img/envelope.svg");
    }
    &.acc-mail {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-acc-email.svg");
    }
    &.acc-date {
        width: 16px;
        height: 15px;
        background-image: url("../img/icon-acc-date.svg");
    }
    &.acc-location {
        width: 15px;
        height: 16px;
        background-image: url("../img/icon-acc-location.svg");
    }
    &.acc-fb {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-acc-fb.svg");
    }
    &.acc-insta {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-acc-insta.svg");
    }
    &.acc-insta {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-acc-insta.svg");
    }
    &.acc-search {
        width: 23px;
        height: 23px;
        background-image: url("../img/icon-acc-magnifier.svg");
    }
    &.acc-logout {
        width: 18px;
        height: 24px;
        background-image: url("../img/icon-acc-logout.svg");
    }
    &.acc-pencil {
        width: 22px;
        height: 23px;
        background-image: url("../img/icon-acc-pencil.svg");
    }
    &.acc-trash {
        width: 22px;
        height: 23px;
        padding-left: 40px;
        background-image: url("../img/icon-acc-trash.svg");
    }
    &.clock {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-clock.svg");
    }
    &.people {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-people.svg");
    }
    &.print {
        width: 20px;
        height: 20px;
        background-image: url("../img/icon-pdf.svg");
    }
    &.close-dis {
        width: 16px;
        height: 16px;
        background-image: url("../img/icon-close.svg");
    }
}
.btn-edit,
.form-style input[type="submit"] {
    font-family: $font-BranB;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    display: inline-block;
    outline: 0;
    min-width: 200px;
    cursor: pointer;
    &.gradient {
        border: 1px solid $gray;
        color: #fff;
        background: $gradientver;
        padding: 12px 25px;
    }
    &.gradient-corner {
        position: relative;
        span {
            color: #fff;
            background: $gradientver;
            padding: 13px 25px;
            position: relative;
            display: block;
            min-width: 250px;
            @include tablet-up {
                min-width: 465px;
            }
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 25px 25px 0;
                border-color: transparent #fff transparent transparent;
                z-index: 1;
            }
            &:after {
                border-width: 0 0 25px 25px;
                border-color: transparent transparent #fff transparent;
                bottom: 0;
                top: auto;
            }
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 25px 0 0;
            border-color: #fff transparent transparent transparent;
            z-index: 1;
        }
        &:after {
            border-width: 25px 0 0 25px;
            border-color: transparent transparent transparent #fff;
            bottom: 0;
            top: auto;
        }
    }
    &.white {
        font-size: 16px;
        color: #49286F;
        text-align: center;
        border: 3px solid $purple;
        padding: 10px 25px;
        background-color: #FFF;
        transition: background 0.4s ease;
        margin-top: 50px;
        &:hover {
            background: $purple;
            color: #fff;
            .arrow-r {
                background-image: url("../img/arrow-r-white.svg");
            }
        }
    }
}
.container {
    @include break-min(2001px) {
        @include max-width-center(80%);
    }
    @include break(1441px,
        2000px) {
        @include max-width-center(84%);
    }
    @include break(1200px,
        1440px) {
        @include max-width-center(92.632%);
    }
    @include break(575px,
        1199px) {
        @include max-width-center(96%);
    }
    &.full-right {
        margin-right: 0;
        padding-right: 0;
        @include break-min(2000px) {
            margin-left: 10%;
            max-width: 90%;
        }
        @include break(1441px,
            2000px) {
            margin-left: 8%;
            max-width: 92%;
        }
        @include break(1200px,
            1440px) {
            margin-left: 3.684%;
            max-width: 96.316%;
        }
        @include tablet {
            max-width: 100%;
            margin-right: auto;
            padding-right: 15px;
        }
    }
    &.no-leftright {
        padding-left: 0;
        padding-right: 0;
    }
    &.normal-padding {
        padding-top: 40px;
        @include tablet-up {
            padding-top: 95px;
        }
    }
}
$width: 15px;
$height: 15px;
// バウンドの距離 Limit Spacing
$bounce_height: 30px;
#sPreLoader {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    &.bg-none {
        background: transparent !important;
    }
    .main-preloader-inner {
        position: absolute;
        width: 100%;
        height: 100vh;
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;
        z-index: 999;
        &.loaded,
        &.bg-none {
            opacity: 0;
            visibility: hidden;
            background: transparent;
        }
        .wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .text {
            color: #49286F;
            display: inline-block;
            margin-left: 15px;
            i.logo {
                background: url(../../upload/logo-blue.svg) no-repeat top left / 100%;
                width: 99px;
                height: 96px;
                display: inline-block;
            }
        }
        .bounceball {
            position: relative;
            display: inline-block;
            height: 37px;
            width: $width;
            &:before {
                position: absolute;
                content: '';
                display: block;
                top: 0;
                width: $width;
                height: $height;
                border-radius: 50%;
                background-color: $blueblack;
                transform-origin: 50%;
                animation: bounce 500ms alternate infinite ease;
            }
        }
    }
}
@keyframes bounce {
    0% {
        top: $bounce_height;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
    }
    35% {
        height: $height;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}
.container-edit {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    @include break-min(2120px) {
        @include max-width-center(80%);
    }
    @include desktop-up {
        @include max-width-center(90%);
    }
    &.full-right {
        margin-right: 0;
        @include break-min(2120px) {
            margin-left: 10%;
            max-width: 90%;
        }
    }
    &.normal-padding {
        padding-top: 95px;
    }
}
.container-events {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    @include tablet-up {
        padding-left: 60px;
        padding-right: 60px;
    }
}
.title-page {
    color: $title-home;
    font-family: $font-BranB;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.9px;
    line-height: 52px;
    &.bold {
        font-weight: 700;
    }
    @include tablet-up {
        font-size: 36px;
    }
    &.margin-bottom {
        margin-bottom: 15px;
        @include tablet-up {
            margin-bottom: 30px;
        }
    }
}
.des-page {
    font-size: 20px;
    color: $blueblack;
    letter-spacing: 0.2px;
    font-weight: 500;
    &.padding-bottom {
        padding-bottom: 20px;
        @include tablet-up {
            padding-bottom: 40px;
        }
    }
    @include tablet-up {
        font-size: 24px;
    }
}
.control-slider,
.owl-carousel.nav-style {
    &>div[class*="btn-"],
    .owl-nav button {
        @include reset-input;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        position: relative;
        z-index: 9;
        transition: all 0.3s ease;
        &:before {
            content: "";
            position: absolute;
            background: rgba($gray, 1);
            height: 1px;
            width: 170px;
        }
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .btn-prev,
    .owl-nav button.owl-prev {
        padding: 0px 0px 10px 25px !important;
        background: transparent url("../img/arrow-black-l.svg") no-repeat top 2px left / 8px auto;
        &:before {
            top: 100%;

            left: 0;
        }
        &:after {
            content: "Previous";
        }
        &:hover {
            padding-left: 17px !important;
        }
    }
    .btn-next,
    .owl-nav button.owl-next {
        padding: 10px 25px 0px 0px !important;
        background: transparent url("../img/arrow-black-r.svg") no-repeat bottom 2px right / auto 15px;
        text-align: right;
        &:before {
            top: 0;
            right: 0;
        }
        &:after {
            content: "Next";
        }
        &:hover {
            padding-right: 17px !important;
        }
    }
    button {
        display: block;
    }
}
.bg-gray {
    background-color: $bg-gray;
}
.bg-gradient {
    background: $gradientver;
}
.bg-gradientver {
    background: $gradient;
}
.btn-addcart {
    display: inline-flex;
    align-items: center;
    font-family: $font-br;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding-right: 10px;
    color: $black;
    &>span {
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $gradientver;
        margin-right: 10px;
    }
}
.form-style {
    input[type="text"],
    input[type="email"],
    input[type="phone"],
    textarea {
        @include reset-input;
        @include reset-autofill($bg-gray);
        background: $bg-gray;
        color: $black;
        display: block;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 5px;
        line-height: 24px;
        padding: 12px 20px;
        font-weight: 600;
        resize: none;
        transition: padding 0.3s ease;
        @include mobile-up {
            padding: 18px 30px;
        }
        @include placeholder {
            color: $black;
        }
    }
    input[type="submit"] {
        @include reset-input;
        border: 1px solid $gray;
        color: #fff;
        background: $gradientver;
        padding: 12px 25px;
        margin-top: 20px;
        margin-bottom: 5px;
        display: block;
    }
}
section.the-banner-page,
.page-404 {
    .banner-text {
        padding-top: 170px;
        padding-bottom: 20px;
        @include tablet-up {
            padding-top: 0;
            padding-bottom: 0;
            max-height: 650px;
            display: flex;
            align-items: center;
        }
    }
    .title {
        font: italic 40px / 1em $font-br;
        color: $blueblack;
        text-transform: uppercase;
        margin-bottom: 20px;
        @include tablet-up {
            font: italic 50px / 52px $font-br;
            margin-bottom: 40px;
        }
    }
    .des {
        color: #333333;
        letter-spacing: 0.2px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        @include tablet-up {
            margin-bottom: 80px;
        }
    }
    .wrap-banner {
        max-width: 575px;
        padding-right: 15px;
        @include tablet-up {
            max-width: 325px;
            padding-top: 50px;
        }
    }
    .wrap-banner-right {
        position: relative;
        @include tablet-up {
            height: 650px;
        }
        @include mobile-down {
            margin-left: -15px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 650px 15vh 0 0;
            border-color: $bg-gray transparent transparent transparent;
            left: -1px;
            top: 0;
            z-index: 2;
            opacity: 0;
            @include tablet-up {
                opacity: 1;
            }
        }
    }
    .img-banner {
        @include tablet-up {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        @include tablet-down {
            padding-top: 56.25%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; // background: rgba($purple, .7);
            z-index: 1;
        }
    }
}
.back-btn {
    &:hover {
        .arrow-back {
            right: 5px;
        }
    }
    span {
        font-size: 20px;
        display: inline-block;
        vertical-align: initial;
        font-weight: 400;
        position: relative;
        top: 2px;
        margin-left: 30px;
        color: #333333;
        @include small-mobile {
            margin-left: 15px;
        }
    }
}
.btn-light {
    height: 46px;
    max-width: 315px;
    width: 100%;
    display: inline-flex;
    font: 600 12px/18px $font-Rr;
    color: #f2f2f2;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: $gradientver;
    transition: all .3s ease;
    &:hover {
        text-decoration: none;
        opacity: .8;
        color: #f2f2f2;
    }
}
.row-edit {
    @include space-col(24px);
    .col-20 {
        @include make-col(1);
        @include tablet-up {
            @include make-col(20,
                100);
        }
    }
}
.woocommerce-message {
    background-color: $purple !important;
}
.toast {
    &.toast-success {
        background-color: $purple !important;
        box-shadow: 0 0 12px #000 !important;
    }
}
.wrtw-required-access {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 50px 0;
}
.deny-access-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    min-height: 38vh;
    @media (max-height: 991px) {
        min-height: 25vh;
    }
    @media (max-height: 768px) {
        min-height: 21vh;
    }
}