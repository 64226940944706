@keyframes popping {
    50% {
        transform: scale(1.15);
    }
}
.breadcrumb {
    padding-bottom: 40px;
    padding-top: 40px;
    @include mobile-down {
        padding-bottom: 30px;
    }
    a {
        display: inline-block;
        font: 500 14px/ normal $font-Rr;
        color: #9B9B9B;
        margin-right: 30px;
        margin-bottom: 5px;
        position: relative;
        @include small-mobile {
            margin-right: 15px;
        }
        &.current-category {
            color: #9B9B9B;
        }
        &:hover {
            text-decoration: none;
            opacity: .8;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-left: 6px solid #9B9B9B;
            border-bottom: 4px solid transparent;
            @include small-mobile-up {
                right: -20px;
            }
        }
        &:last-child {
            pointer-events: none;
        }
        &:first-child,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
.title-product {
    font: 500 24px/ normal $font-Pop;
    color: #494949;
    line-height: 35/24*1em;
    margin-bottom: 25px;
    // &.title-event-product {
    //     font-size: 14px;
    //     line-height: 24/14*1em;
    //     color: #494949;
    //     margin-bottom: 5px;
    //     font-family: $font-Pop;
    //     font-weight: 400;
    // }
}
.related-products {
    min-height: 600px;
    .title-product {
        color: #494949;
    }
    .woocommerce-Price-amount {
        &.amount {
            color: #494949;
        }
    }
}
.product-slide-block {
    background: #fff;
    max-width: 315px;
    transition: all .5s ease;
    border: 2.5px solid transparent;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include tablet-down {
        border: 2.5px solid #353869;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .img-product {
        margin-bottom: 20px;
        border: 1px solid #F2F2F2;
        background-color: #fff;
        background-size: contain;
        transition: all .3s ease;
        img {
            transition: all .3s ease;
            object-fit: contain;
        }
    }
    h3 {
        padding-left: 10px;
        padding-right: 10px;
        text-transform: uppercase;
        margin-bottom: 15px;
        transition: all .3s ease;
        font-size: 18px;
    }
    .inner-price {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        ins {
            text-decoration: none;
            margin-right: 5px;
            order: 1;
        }
        del {
            color: red;
            order: 2;
            span {
                font-size: 16px;
                color: #bebcbc;
                font-weight: 500;
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .models {
        transition: all .3s ease;
        margin-left: auto;
        font: 600 14px/ normal $font-Rr;
        text-transform: uppercase;
        order: 3;
        @include tablet-up {
            opacity: 0;
        }
    }
    .hover-toggle {
        transition: all .4s ease;
        @include tablet-up {
            height: 0;
        }
    }
    .color-product {
        cursor: pointer;
        border: 1px solid #F2F2F2;
        margin: 0 auto;
        height: 70px;
        img {
            object-fit: contain;
        }
        &.selected-color {
            border-color: #333;
        }
        &:hover {
            opacity: .8;
        }
    }
    .add-to-cart {
        text-align: center;
        position: relative;
        background: $gradient;
        color: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font: 700 16px/ normal $font-br;
        letter-spacing: 1px;
        z-index: 9;
        i {
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 25px;
            transform: translate3d(0, -50%, 0);
            &.fa-spinner {
                top: 35%;
            }
        }
    }
    &:hover {
        border: 2.5px solid #353869;
        .img-product {
            background-color: #ECEDEF;
            img {
                opacity: 0;
            }
        }
        h3 {
            color: $purple;
        }
        .models {
            opacity: 1;
        }
        .hover-toggle {
            @include tablet-up {
                height: 208px;
            }
            &.is-not-size {
                @include tablet-up {
                    height: 135px;
                }
            }
            &.is-not-color {
                height: 65px;
            }
        }
    }
    &.event-product-block {
        &:hover {
            .hover-toggle {
                @include tablet-up {
                    height: 65px;
                }
            }
        }
    }
}
select.size-option {
    @include reset-input;
    border-radius: 0;
    max-width: 220px;
    width: calc(100% - 30px);
    font: 600 14px/ normal $font-Rr;
    color: #333;
    border: 1px solid #A7AAAA;
    height: 50px;
    padding-left: 25%;
    cursor: pointer;
    outline: none;
    background: transparent url('../img/arrow-down.svg') right 45% center/ 15px 7px no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
}
.product-slide {
    &>.owl-nav {
        z-index: -1;
        position: absolute;
        top: 500px;
        width: 100%;
        font: 600 12px/ normal $font-Rr;
        color: #333333;
        @include tablet-down {
            display: none;
        }
        button.owl-prev {
            content: "";
            text-align: left;
            background: transparent url(../img/prev-btn.svg) left center/contain no-repeat !important;
            position: absolute;
            top: 0;
            right: 42px;
            outline: none;
            z-index: -1;
            transition: all .3s ease;
            width: 32px;
            height: 32px;
            &:hover {
                opacity: .7;
            }
            &.disabled {
                opacity: 0;
                visibility: hidden;
            }
        }
        button.owl-next {
            content: "";
            background: transparent url(../img/next-btn.svg) right center/contain no-repeat !important;
            position: absolute;
            top: 0;
            right: 0;
            outline: none;
            width: 32px;
            height: 32px;
            z-index: -1;
            transition: all .3s ease;
            &:hover {
                opacity: .7;
            }
            &.disabled {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
.owl-inner-slide-product {
    width: 100%;
    margin: 0 auto 15px;
    &>.owl-nav {
        position: absolute;
        height: 100%;
        z-index: 0;
        width: 100%;
        button.owl-prev {
            top: -100%;
            width: 45px;
            height: 100%;
            position: absolute;
            left: 0;
            background: linear-gradient(to right, #D8D8D8, rgba(238, 238, 238, 0.0001)) !important;
            outline: none;
            &:before {
                content: "";
                background: transparent url(../img/icon-slider-prev.svg) center center/10px 20px no-repeat;
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                width: 10px;
                height: 20px;
                left: 15px;
            }
            &.disabled {
                display: none;
            }
        }
        button.owl-next {
            top: -100%;
            right: 0;
            width: 45px;
            height: 100%;
            position: absolute;
            background: linear-gradient(to right, rgba(238, 238, 238, 0.0001), #D8D8D8) !important;
            outline: none;
            &:before {
                content: "";
                background: transparent url(../img/icon-slider-next.svg) center center/10px 20px no-repeat;
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                width: 10px;
                height: 20px;
                right: 15px;
            }
            &.disabled {
                display: none;
            }
        }
    }
}
//***************//
//PRODUCT SINGLE//
//*************//
.product-single {
    padding-bottom: 70px;
    @include tablet-down {
        padding-bottom: 50px;
    }
    .title-product {
        margin-bottom: 10px;
        font-size: 20px;
    }
}
.single-product-container {
    max-width: 1150px;
}
.product-single-img {
    width: 650px;
    margin-right: 20px;
    float: left;
    position: relative;
    @include desktop-down {
        margin: 0 auto 20px;
        float: none;
    }
    @include tablet-down {
        width: 100%;
        max-width: 650px;
    }
    @include mobile-down {
        max-width: 480px;
        margin: 0 auto 100px;
    }
    .nav-prev,
    .nav-next {
        position: absolute;
        width: 25px;
        height: 25px;
        background: transparent url('../img/arrow-down.svg') center/contain no-repeat;
        cursor: pointer;
        z-index: 2;
        &:hover {
            opacity: .8;
        }
        @include mobile-down {
            bottom: -50px;
        }
        @include small-mobile {
            bottom: -40px;
        }
    }
    .nav-prev {
        display: none;
        transform: rotate(90deg);
        left: 0;
        @include mobile-up {
            left: 32px;
            transform: rotate(180deg);
            top: -30px;
        }
    }
    .nav-next {
        right: 0;
        transform: rotate(-90deg);
        @include mobile-up {
            bottom: 0;
            left: 32px;
            transform: rotate(0);
            right: auto;
        }
    }
}
.instruction-line {
    display: block;
    text-align: center;
    padding-left: 120px;
    padding-top: 18px;
    font-size: 14px;
    color: $headingtext;
    @include mobile-down {
        padding-left: 0;
    }
}
.owl-product-single {
    width: calc(100% - 120px);
    background: #fff;
    margin-left: auto;
    position: relative;
    @include mobile-down {
        width: 100%;
    }
    .magnify {
        width: 100%;
        padding-top: 100%;
        position: relative;
        height: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            object-fit: contain;
            font-family: 'object-fit: contain;';
            transform: translate3d(-50%, -50%, 0);
        }
    }
    .owl-dots {
        position: absolute;
        top: 10px;
        left: -120px;
        width: 93px;
        @include mobile-down {
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            width: 100%;
            transform: translate3d(0, calc(100% + 45px), 0);
            display: block;
            text-align: center;
            left: 0;
        }
    }
    button.owl-dot {
        display: none;
        margin-bottom: 15px;
        width: 93px;
        height: 93px;
        outline: none;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #fff;
        transition: all .3s ease;
        position: relative;
        border: 3px solid #A7AAAA;
        @include mobile-down {
            width: 72px;
            height: 72px;
            border-width: 3px;
            margin-top: 0;
            margin-right: 10px;
            margin-bottom: 7px;
        }
        @include small-mobile {
            width: 50px;
            height: 50px;
            margin-right: 7px;
        }
        &.active {
            border-width: 3px;
            border-color: #4C3274;
        }
        &.show {
            display: inline-block;
        }
    }
    .owl-item.animated {
        animation-duration: .25s;
    }
}
.product-single-info {
    float: left;
    width: 430px;
    @include desktop-down {
        margin: 0 auto 20px;
        float: none;
        max-width: 430px;
        width: 100%;
    }
    .heading-text {
        color: #9B9B9B;
    }
}
.product-single-top {
    padding-bottom: 30px;
}
.product-single-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
    color: $blueblack;
}
.product-single-type {
    color: #999990;
    font-family: $font-br;
    font-weight: 700;
    text-transform: uppercase;
}
.heading-text {
    font-size: 14px;
    display: flex;
    margin-bottom: 5px;
    span.total-colors {
        margin-left: auto;
        color: $grey;
    }
    span.color-name {
        text-transform: uppercase;
    }
    .hyper-link-direct {
        margin-left: auto;
    }
}
.product-single-price {
    padding-bottom: 50px;
    .price-number {
        font: 900 42px/50px $font-br;
        font-style: italic;
        margin-bottom: 5px;
        ins {
            float: left;
            text-decoration: none;
            margin-right: 15px;
        }
        del {
            color: red;
            span {
                font-size: 21px;
                color: #bebcbc;
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
.link-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: center;
    a {
        line-height: 24/14*1em;
    }
}
.hyper-link-direct {
    color: #0066C0;
    font-size: 14px;
}
.owl-colors {
    margin-bottom: 30px;
    .owl-item {
        margin-left: -1px;
    }
    .owl-stage .color-product {
        cursor: pointer;
        border: 1px solid #F2F2F2;
        margin: 0 auto;
        height: 64px;
        @include small-mobile {
            max-width: 70px;
        }
        img {
            object-fit: contain;
            height: 100%;
        }
        &.selected-color {
            border-color: #333;
        }
        &.out-of-stock {
            border-color: rgba(208, 2, 27, 0.1474);
            &:after {
                position: absolute;
                background: rgba(208, 2, 27, 0.2);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "";
            }
        }
        &:hover {
            opacity: .8;
        }
    }
    &>.owl-nav {
        position: absolute;
        height: 100%;
        z-index: 0;
        width: 100%;
        button.owl-prev {
            top: -100%;
            width: 45px;
            height: 100%;
            position: absolute;
            left: 0;
            background: linear-gradient(to right, #D8D8D8, rgba(238, 238, 238, 0.0001)) !important;
            outline: none;
            &:before {
                content: "";
                background: transparent url(../img/icon-slider-prev.svg) center center/10px 20px no-repeat;
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                width: 10px;
                height: 20px;
                left: 15px;
            }
            &.disabled {
                display: none;
            }
        }
        button.owl-next {
            top: -100%;
            right: 0;
            width: 45px;
            height: 100%;
            position: absolute;
            background: linear-gradient(to right, rgba(238, 238, 238, 0.0001), #D8D8D8) !important;
            outline: none;
            &:before {
                content: "";
                background: transparent url(../img/icon-slider-next.svg) center center/10px 20px no-repeat;
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                width: 10px;
                height: 20px;
                right: 15px;
            }
            &.disabled {
                display: none;
            }
        }
    }
}
.size-qty-holder {
    display: flex;
    margin: 0 -10px;
    position: relative;
    z-index: 1;
    .size-holder,
    .qty-holder {
        width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        .hyper-link-direct {
            float: right;
        }
    }
    .size-option {
        width: 100%;
    }
    &.event-qty {
        margin-bottom: 25px;
    }
}
.qty-box {
    height: 50px;
    border: 1px solid $grey;
    position: relative;
    input {
        border: 0;
        outline: none;
        text-align: center;
        height: 48px;
        width: 100%;
        background: transparent;
        padding: 0 55px;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }
    }
    .minus-btn,
    .add-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 500 24px/ normal $font-br;
        color: $grey;
        cursor: pointer;
    }
    .minus-btn {
        left: 0;
        border-right: 1px solid $grey;
    }
    .add-btn {
        right: 0;
        border-left: 1px solid $grey;
    }
}
.add-to-cart-single {
    text-align: center;
    position: relative;
    background: $gradient;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font: 700 16px/ normal $font-br;
    letter-spacing: 1px;
    transition: all .3s ease;
    margin-bottom: 15px;
    i {
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translate3d(0, -50%, 0);
        &.fa-spinner {
            top: 30%;
        }
    }
    &:hover {
        opacity: .8;
    }
}
.add-to-list {
    cursor: pointer;
    font-family: $font-br;
    text-transform: uppercase;
    margin-bottom: 25px;
    &:hover {
        opacity: .8;
    }
}
.social-share-list {
    border-top: 1px solid #979797;
    display: flex;
    align-items: center;
    .hyper-link-direct {
        margin-left: auto;
        margin-right: 15px;
    }
    &.event-socials {
        .hyper-link-direct {
            margin-left: 0;
        }
    }
}
.jssocials {
    .jssocials-share {
        padding: 5px;
        margin-right: 20px;
        &:hover {
            filter: brightness(90%);
        }
        &:last-child {
            margin-right: 0;
        }
        &.jssocials-share-email {
            background: #df4932;
            a {
                color: #fff;
            }
        }
        &.jssocials-share-facebook {
            background: #4460A0;
            a {
                color: #fff;
            }
            color: #fff;
        }
        &.jssocials-share-twitter {
            background: #4DC8F1;
            a {
                color: #fff;
            }
            color: #fff;
        }
        &.jssocials-share-pinterest {
            background: #CC2127;
            a {
                color: #fff;
            }
        }
    }
}
.composition {
    padding: 12px 15px;
    border: 2px solid #21285D;
    margin-top: 15px;
    .product-element {
        display: inline-block;
        padding-right: 15px;
        font-weight: 700;
        letter-spacing: .2px;
        line-height: 24px;
    }
}
.product-description {
    color: #494949;
    font-family: $font-Pop;
    font-size: 16px;
    font-weight: 400;
    line-height: 24/14*1em;
    img {
        max-width: 100%;
    }
    p {
        margin-bottom: .5rem;
    }
    h3 {
        font: 600 18px/1.5em $font-Pop;
        color: #999999;
        text-transform: uppercase;
    }
    @include desktop-up {
        position: relative;
        margin-top: 50px;
        width: calc(55%);
    }
    .title-product {
        color: #999990;
    }
    .shipping-info {
        a {
            color: #1572c5;
            font-weight: 500;
        }
    }
    &.events-description {}
}
//****************//
//PRODUCT ARCHIVE//
//**************//
.product-archive {
    .container {
        &>.row {
            @include desktop-up {
                @include space-col(100px);
            }
            @include desktop {
                @include space-col(50px);
            }
        }
    }
    .product-slide-block {
        margin-bottom: 30px;
    }
}
.coll-item-product {
    &:not(:first-child) {
        padding-top: 10px;
    }
    &:last-child {
        padding-bottom: 15px;
    }
    &>a[data-toggle="collapse"] {
        text-decoration: none;
        display: block;
        position: relative;
        margin-bottom: 20px;
        .title {
            color: #333;
            text-transform: uppercase;
            font-weight: 700;
            font-family: $font-br;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 7px;
            width: 15px;
            height: 8px;
            background: transparent url("../img/arrow-up-black.svg") no-repeat center / auto 100%;
            transition: transform 0.3s ease;
        }
        &.collapsed {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }
    .show-more {
        font-size: 12px;
        color: #333;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 20px;
        display: inline-block;
        &:before {
            content: "\002b\00a0show more"
        }
        &[aria-expanded="true"] {
            &:before {
                content: "\002d\00a0show less"
            }
        }
        &:hover {
            opacity: .8;
            text-decoration: none;
        }
    }
}
.checkbox-holder {
    input {
        display: none;
        &:checked {
            &+label:after {
                transform: scale(1) rotate(125deg);
                opacity: 1;
            }
        }
    }
    label {
        text-transform: uppercase;
        padding-left: 32px;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 18px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #333;
            width: 16px;
            height: 16px;
        }
        &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 2px;
            transform: scale(1.3) rotate(125deg);
            width: 18px;
            height: 8px;
            border-top: 1px solid $purple;
            border-right: 1px solid $purple;
            transition: all .3s ease;
            opacity: 0;
        }
    }
}
.checkbox-holder-size {
    display: inline-block;
    input {
        display: none;
        &:checked {
            &+label {
                background: $purple;
                color: #fff;
                box-shadow: 0 0 4px rgba(#000, .3);
            }
        }
    }
    label {
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 18px;
        width: 42px;
        height: 42px;
        margin-right: 12px;
        border: 1px solid #333;
        transition: all .3s ease;
    }
}
.checkbox-holder-color {
    display: inline-block;
    &.ffffff {
        label {
            border: 1px solid #000;
        }
    }
    input {
        display: none;
        &:checked {
            &+label {
                box-shadow: 0 0 4px rgba(#000, .4);
                border-radius: 50%; // &:before {
                //     transform: scale(1) rotate(125deg);
                //     opacity: 1;
                // }
            }
        }
    }
    label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 16px;
        height: 16px;
        transition: all .3s ease;
        &:hover {
            transform: scale(1.15);
        }
        @include mobile-down {
            width: 24px;
            margin-right: 12px;
            height: 24px;
        }
        &:before {
            content: '';
            position: absolute;
            top: -3px;
            left: 2px;
            transform: scale(1.3) rotate(125deg);
            width: 18px;
            height: 8px;
            border-top: 1px solid #333;
            border-right: 1px solid #333;
            transition: all .3s ease;
            opacity: 0;
            @include mobile-down {
                top: -1px;
                left: 2px;
                width: 27px;
                height: 8px;
            }
        }
    }
}
.owl-promote-product {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    .img-drop {
        padding-top: 100%/(990/440);
    }
    .owl-item {
        &.animated {
            animation-duration: .25s;
        }
    }
    &>.owl-nav {
        z-index: 2;
        position: absolute;
        bottom: 55px;
        width: 100%;
        font: 600 12px/ normal $font-Rr;
        color: #fff;
        @include small-mobile {
            bottom: 45px;
        }
        button.owl-prev {
            content: "";
            text-align: left;
            background: transparent url(../img/icon-slider-prev-white.svg) left top/10px 15px no-repeat !important;
            position: absolute;
            top: 0;
            left: 30px;
            outline: none;
            z-index: 2;
            transition: all .3s ease;
            padding: 2px 0 8px 20px !important;
            width: 170px;
            @include small-mobile {
                width: 120px;
                left: 15px;
            }
            span {
                background: transparent url(../img/icon-slider-prev-white.svg) left top/10px 15px no-repeat !important;
                width: 10px;
                height: 15px;
                position: absolute;
                left: 0;
                top: 1px;
            }
            &:before {
                content: "";
                background: #4C3274;
                width: 147px;
                height: 147px;
                transform: rotate(45deg);
                position: absolute;
                bottom: -105px;
                left: -105px;
                z-index: -1;
            }
            &:after {
                width: 170px;
                content: "";
                bottom: 0;
                position: absolute;
                left: 0;
                background: #D8D8D8;
                height: 1px;
                @include small-mobile {
                    width: 120px;
                }
            }
            &:hover {
                opacity: .7;
            }
            &.disabled {
                opacity: 0;
                visibility: hidden;
            }
        }
        button.owl-next {
            content: "";
            background: transparent url(../img/icon-slider-next-white.svg) right bottom/10px 15px no-repeat !important;
            position: absolute;
            top: 0;
            right: 30px;
            outline: none;
            z-index: 1;
            transition: all .3s ease;
            padding: 10px 20px 0px 0 !important;
            width: 170px;
            height: 27px;
            text-align: right;
            @include small-mobile {
                width: 120px;
                right: 15px;
            }
            span {
                background: transparent url(../img/icon-slider-next-white.svg) left top/10px 15px no-repeat !important;
                width: 10px;
                height: 15px;
                position: absolute;
                right: 0;
                bottom: 1px;
            }
            &:hover {
                opacity: .7;
            }
            &:before {
                content: "";
                background: #4C3274;
                width: 147px;
                height: 147px;
                transform: rotate(-45deg);
                position: absolute;
                bottom: -105px;
                right: -105px;
                z-index: -1;
            }
            &:after {
                width: 170px;
                content: "";
                top: 0;
                position: absolute;
                left: 0;
                background: #D8D8D8;
                height: 1px;
                @include small-mobile {
                    width: 120px;
                }
            }
            &.disabled {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
.featured-products,
.new-release {
    &>.d-flex {
        flex-wrap: wrap;
        padding-bottom: 40px;
        @include tablet-down {
            padding-bottom: 30px;
        }
        @include mobile-down {
            flex-direction: column;
        }
    }
}
.title-section-product {
    font-size: 24px;
    color: #4C3274;
    font-weight: 700;
    margin-bottom: 0;
    padding-right: 15px;
    @include tablet-down {
        margin-bottom: 10px;
        font-size: 20px;
    }
    &.title-product-biggap {
        margin-bottom: 40px;
        @include small-mobile {
            margin-bottom: 25px;
        }
    }
    .numb-products {
        margin-left: 16px;
        font-weight: 600;
        font-size: 14px;
        color: #333;
        text-transform: uppercase;
        .facetwp-counts {
            display: inline-block;
        }
    }
}
.featured-products-slide {
    .row {
        @include space-col(16px);
        @include desktop {
            @include space-col(10px);
        }
    }
}
.products-nav {
    margin-left: auto;
    position: relative;
    width: 205px;
    background: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .products-nav-l {
        cursor: pointer;
        position: absolute;
        width: 10px;
        height: 15px;
        display: inline-block;
        background: transparent url(../img/icon-slider-prev.svg) left top/10px 15px no-repeat !important;
        left: 0;
        bottom: 2px;
        &:hover {
            opacity: .7;
        }
    }
    .products-nav-r {
        cursor: pointer;
        position: absolute;
        width: 10px;
        height: 15px;
        display: inline-block;
        background: transparent url(../img/icon-slider-next.svg) left top/10px 15px no-repeat !important;
        right: 0;
        bottom: 2px;
        &:hover {
            opacity: .7;
        }
    }
    .products-paging {
        .current-page {
            font: 700 16px/ normal $font-br;
        }
        .total-page {
            font: 400 14px/ normal $font-br;
            color: $grey;
        }
    }
}
.new-release-nav-holder {
    position: relative;
    padding-bottom: 50px;
    @include tablet-down {
        padding-bottom: 30px;
    }
    .products-nav {
        margin: 0;
        position: absolute;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        padding: 0 50px;
        width: 350px;
        .products-nav-l {
            left: 75px;
        }
        .products-nav-r {
            right: 75px;
        }
    }
}
.sort-option {
    @include reset-input;
    border-radius: 0;
    max-width: 200px;
    width: 100%;
    font: 600 14px/ normal $font-Rr;
    color: #333;
    border: 1px solid #A7AAAA;
    height: 40px;
    padding-left: 5%;
    cursor: pointer;
    outline: none;
    background: transparent url('../img/arrow-down.svg') right 15% center/ 15px 7px no-repeat;
    appearance: none;
    position: relative;
    z-index: 1;
    margin-left: auto;
}
.facetwp-facet-categories,
.facetwp-facet-gender_filter {
    .facetwp-checkbox {
        text-transform: uppercase;
        padding-left: 32px;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 18px;
        background: none;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #333;
            width: 16px;
            height: 16px;
        }
        &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 2px;
            transform: scale(1.3) rotate(125deg);
            width: 18px;
            height: 8px;
            border-top: 1px solid #49286F;
            border-right: 1px solid #49286F;
            transition: all .3s ease;
            opacity: 0;
        }
        &.checked {
            background: none;
            &:after {
                transform: scale(1) rotate(125deg);
                opacity: 1;
            }
        }
    }
    .facetwp-counter {
        font-size: 12px;
        font-weight: 500;
        color: rgb(49, 49, 49);
        font-family: $font-br;
    }
}
.facetwp-facet-size_filter {
    .facetwp-counter {
        display: none;
    }
    .facetwp-checkbox {
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 18px;
        width: 42px;
        height: 42px;
        margin-right: 12px;
        border: 1px solid #333;
        transition: all .3s ease;
        background: none;
        padding-left: 0;
        &.checked {
            background: none;
            background-color: $purple;
            color: #fff;
            box-shadow: 0 0 4px rgba(#000, .3);
        }
    }
}
.facetwp-facet-color_filter {
    .facetwp-color {
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 16px;
        height: 16px;
        box-shadow: none;
        transition: all .3s ease;
        &:hover {
            transform: scale(1.15);
        }
        &.checked {
            box-shadow: none;
            &:after {
                content: '';
                position: absolute;
                top: -2px;
                left: 2px;
                transform: rotate(125deg);
                width: 18px;
                height: 8px;
                border: 0;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                margin: 0;
            }
        }
    }
}
.post-type-archive {
    .product-archive {
        margin-top: 50px;
    }
}
.product-popup-video {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.51);
        z-index: 1;
    }
    .fa-play-circle {
        font-size: 52px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 2;
        opacity: 0.6;
    }
}
.product-events {
    .product-single-price {
        padding-bottom: 0;
    }
}
.woocommerce-Price-amount {
    &.amount {
        color: $blueblack;
    }
}
.size-qty-holder,
.product-single-price {
    .heading-text {
        color: $headingtext;
    }
}
.hyper-link-direct {
    color: $headingtext;
}
.product-single-info {
    input[type="number"] {
        font-family: $font-Pop;
        color: $blueblack;
        font-size: 20px;
        @include small-mobile-up {
            font-size: 24px;
        }
    }
}
.product-single-events {
    p {
        color: $headingtext;
    }
}
.product-archive {
    .coll-item-product {
        .title {
            color: $greysecond !important;
        }
    }
    .facetwp-checkbox {
        color: $headingtext;
    }
    .facetwp-toggle {
        color: $greysecond !important;
    }
    .facetwp-counter {
        display: none;
    }
    #coll-size {
        .facetwp-checkbox {
            color: $black;
            &.checked {
                color: #fff !important;
            }
        }
    }
    .woocommerce-info {
        background-color: transparent;
    }
}
.loading-testing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ven-sidebar {
    .facetwp-checkbox[data-value="events"] {
        display: none !important;
    }
}