// @font-face {
//     font-family: 'MyWebFont';
//     src: url('webfont.eot');
//     /* IE9 Compat Modes */
//     src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//     url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
//     url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
//     url('webfont.ttf') format('truetype'), /* Safari, Android, iOS */
//     url('webfont.svg#svgFontName') format('svg');
//     /* Legacy iOS */
// }
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:500,500i,600');
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Bold.eot');
    src: url('../fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Bold.woff') format('woff'), url('../fonts/BrandonGrotesque-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BlackItalic.eot');
    src: url('../fonts/BrandonGrotesque-BlackItalic?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-BlackItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-BlackItalic.ttf') format('truetype');
    font-style: italic;
}
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Medium.eot');
    src: url('../fonts/BrandonGrotesque-Medium?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Medium.woff') format('woff'), url('../fonts/BrandonGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Regular.woff') format('woff'), url('../fonts/BrandonGrotesque-Regular.ttf') format('truetype');
    font-weight: 400;
}
//GOTHAM FONT
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.eot');
    src: url('../fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-XLight.woff') format('woff'),
        url('../fonts/Gotham-XLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}