.scroll-grid-content {
    .vertical-wrapper {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .meal-wrap {
            overflow: hidden;
            overflow-y: scroll;
            height: 100%;
            .wrap-row {
                display: flex;
                align-items: flex-start;
                @include small-mobile {
                    &:not(:first-child) {
                        display: none;
                    }
                }
                &.none {
                    display: none;
                    &.active {
                        display: flex;
                    }
                }
                .navbar-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    min-width: 60px;
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);
                    padding-bottom: 25px;
                    margin-right: 12px;
                    margin-bottom: 30px;
                    @include mobile-up {
                        min-width: 90px;
                    }
                    a {
                        position: relative;
                        min-width: 22px;
                        text-decoration: none;
                        color: $black-acc;
                        font-family: $font-Pop;
                        text-transform: capitalize;
                        font-size: 14px;
                        transition: color .2s ease-in-out;
                        &:before {
                            content: "";
                            position: absolute;
                            margin-left: -1px;
                            width: 1px;
                            height: 100%;
                            overflow: hidden;
                            background-color: $black-acc;
                            transform: scaleY(0);
                            transition: all .3s cubic-bezier(.215, .61, .355, 1);
                        }
                        &:hover {
                            &:before {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
                .list-meal {
                    flex: calc(100% - 90px);
                    background: $text-gray;
                    list-style: none;
                    overflow: hidden;
                    .meal-item {
                        @include make-col(1, 1);
                        padding: 10px 5px;
                        @include desktop-up {
                            @include make-col(1, 5);
                            padding: 25px 12px;
                        }
                        @include desktop {
                            @include make-col(1, 3);
                            padding: 25px 12px;
                        }
                        @include mobile-tablet {
                            @include make-col(1, 2);
                        }
                        @include small-mobile {
                            flex: 0 0 80%;
                            max-width: 80%;
                        }
                    }
                    .wrap-meal {
                        text-decoration: none;
                        display: block;
                        position: relative;
                        width: 100%;
                        .img-drop {
                            padding-top: 320/240*100%;
                            overflow: hidden;
                            img {
                                transform: scale(1);
                                transition: all 0.5s ease;
                            }
                        }
                        .wrap-text {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            font-family: $font-Pop;
                            font-size: 14px;
                            line-height: 20/14*1em;
                            padding: 30px 25px;
                            @include small-mobile {
                                font-weight: 500;
                                font-size: 16px;
                            }
                            .meal-type {
                                text-transform: uppercase;
                                color: $gray-text;
                                margin-bottom: 25px;
                            }
                            .meal-name {
                                font-weight: 600;
                                color: #FFFFFF;
                            }
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            width: 100%;
                            height: 50%;
                            background: linear-gradient(360deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
                            mix-blend-mode: normal;
                            opacity: 0.81;
                        }
                        &:hover {
                            .img-drop img {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
                &:first-child {
                    .navbar-item {
                        padding-bottom: 35px;
                        @include tablet-up {
                            padding-bottom: 50px;
                        }
                    }
                }
                .group-meal {
                    display: flex;
                    width: 100%;
                    padding-left: 0px;
                    @include make-row(12px);
                    margin-left: 0;
                    margin-right: 0;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    @include desktop-up {
                        padding-left: 12px;
                        @include make-row(0px);
                    }
                    @include tablet-desktop {
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}
.meal-plan-modal {
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 23px;
    z-index: 15;
    opacity: 0;
    &.is-fixed {
        position: fixed;
        width: 100%;
        opacity: 1;
        display: block;
        @include mobile-down {
            top: 88px;
        }
    }
    &.is-mobile {
        overflow: scroll;
        max-height: calc(100vh - 88px);
        @include mobile-up {
            max-height: calc(100vh - 158px);
        }
    }
    .row {
        margin-right: 0;
        margin-left: 0;
    }
    .bg-white {
        background-color: #FFF;
        @include tablet-down {
            padding-right: 0;
            padding-left: 0;
        }
    }
    .wrap-info-img {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        .img-drop {
            padding-top: 237/375*100%;
            z-index: 0;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(rgba(#000, .7), rgba(#000, .3));
            }
            @include small-mobile-up {
                padding-top: 653/513*100%;
            }
            @include tablet-up {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                padding-top: 0;
            }
        }
        .wrap-info {
            padding: 25px 25px 10px;
            background: #fff;
            @include small-mobile-up {
                position: absolute;
                top: 0;
                left: 0;
                background: transparent;
            }
            @include mobile-up {
                padding: 50px 25px;
            }
            .wrap-text {
                h3 {
                    font-size: 24px;
                    line-height: 35/24*1em;
                    color: $orange;
                    text-transform: uppercase;
                    @include small-mobile-up {
                        margin-bottom: 0;
                    }
                }
                >span {
                    display: block;
                    font-weight: 500;
                    color: #d2d2d2;
                    @include small-mobile-up {
                        color: #DEDEDE;
                    }
                }
                .desc {
                    margin-top: 10px;
                    color: #616161;
                    @include small-mobile-up {
                        max-height: 170px;
                        color: #DEDEDE;
                    }
                    @include mobile-up {
                        max-height: 200px;
                        margin-top: 20px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
            }
        }
        .wrap-spec {
            font-size: 14px;
            width: 100%;
            background: #fff;
            padding: 15px 25px;
            @include small-mobile-up {
                position: absolute;
                bottom: 40px;
                left: 25px;
                max-width: 170px;
                background: transparent;
                padding: 0;
            }
            .list-spec {
                list-style: none;
                margin-bottom: 0;
                margin-bottom: 13px;
                color: #000;
                @include mobile-down {
                    padding-left: 0;
                }
                @include small-mobile-up {
                    color: #DEDEDE;
                }
                li {
                    align-items: center;
                    @include small-mobile-up {
                        display: inline-flex;
                    }
                    &:not(:last-child) {
                        margin-bottom: 13px;
                    }
                    i {
                        margin-right: 10px;
                    }
                }
            }
            .btn-print {
                background-color: $orange;
                color: #FFF;
                padding: 7px 0;
                text-decoration: none;
                text-align: center;
                display: none;
                width: 100%;
                justify-content: center;
                align-items: center;
                transition: opacity 0.5s ease;
                @include small-mobile-up {
                    display: inline-flex;
                }
                span {
                    display: inline-block;
                    margin-left: 10px;
                }
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .wrap-info-content {
        color: #494949;
        padding: 25px 25px;
        @include small-mobile-up {
            min-height: calc(100% - 88px);
            max-height: calc(100% - 88px);
            padding: 50px 25px;
        }
        @include tablet-up {
            min-height: calc(100vh - 118px);
            max-height: calc(100vh - 118px);
            padding: 50px 56px;
        }
        .list-nav-content {
            position: relative;
            margin-bottom: 30px;
            @include small-mobile-up {
                margin-bottom: 40px;
            }
            .list-dir {
                @include small-mobile-up {
                    border-bottom: 1px solid #DEDEDE;
                }
                li {
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
                a {
                    color: #D1D1D1;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    position: relative;
                    transition: all 0.5s ease;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $orange;
                        transform: scale(0);
                        transition: all 0.5s ease;
                        transform-origin: left center;
                    }
                    &.active,
                    &:hover {
                        color: $orange;
                        &:after {
                            transform: scale(.85);
                            @include small-mobile-up {
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
            .btn-dismiss {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: #C7C7C7;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                @include small-mobile {
                    position: fixed;
                    right: 20px;
                    top: 100px;
                    width: 36px;
                    height: 36px;
                }
            }
        }
        .wrap-content-scroll {
            @include small-mobile-up {
                max-height: calc(100vh - 300px) !important;
            }
            @include mobile-up {
                max-height: calc(100vh - 275px);
            }
            h3 {
                @include small-mobile {
                    font-size: 24px;
                    line-height: 35/24*1em;
                }
            }
            p,
            li {
                @include small-mobile {
                    font-size: 14px;
                    line-height: 24/14*1em;
                }
            }
            ol {
                list-style: none;
                counter-reset: item;
                padding-left: 0;
                li {
                    counter-increment: item;
                    position: relative;
                    padding-left: 35px;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    &:before {
                        content: counter(item)'.';
                        position: absolute;
                        left: 0;
                    }
                }
            }
            .tab-content {
                .tab-pane {
                    p {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
            }
        }
    }
}
.btn-normal {
    background-color: $orange;
    border: none;
    padding: 10px 45px;
    text-align: center;
    margin: 30px auto 0 auto;
    display: inherit;
    color: #fff;
    font-weight: 600;
    min-width: 250px;
    &.btn-reset-filter {
        background: $gray-acc;
        color: #fff;
    }
}
.week-list {
    @include mobile-down {
        display: none;
    }
}
.week-list-filter-mb {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    &.active {
        display: block;
    }
}
.week-list-mb {
    display: none;
    @include mobile-down {
        display: block;
    }
}
.meal-plan-filter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    font-family: $font-Pop;
    font-weight: 400;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 1px;
        background: rgba(#000, .1);
    }
    .meal-plan-day {
        width: 50%;
        text-align: center;
        ul {
            padding-left: 0;
        }
        li {
            a {
                &.active {
                    &::before {
                        background-color: $purple;
                    }
                }
            }
        }
    }
    .meal-plan-week {
        width: 50%;
        text-align: center;
        ul {
            padding-left: 0;
        }
    }
    li {
        list-style: none;
        margin-bottom: 35px;
        a {
            position: relative;
            padding: 10px 25px;
            margin-bottom: 25px;
            color: #000;
            &:hover {
                cursor: pointer;
                text-decoration: none;
            }
            &.active {
                color: #fff;
                text-decoration: none;
                &:before {
                    background-color: $orange;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                    -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                }
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                -webkit-transform: skewX(-15deg);
                transform: skewX(-15deg);
                z-index: -1;
                -webkit-box-shadow: none;
                box-shadow: none;
                -webkit-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }
        }
    }
}
.meal-back {
    font-family: 'Poppins', sans-serif;
    color: #000;
    opacity: 0.5;
    font-weight: 100;
    padding-left: 5px;
}
.meal-plan-back {
    padding: 15px 25px;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    i {
        color: #000;
        opacity: 0.5;
    }
}
.header-workout {
    .previous-btn {
        @include mobile-down {
            background: transparent url(../img/btn-back-gray.svg) left center/30px 30px no-repeat;
        }
    }
    .gray-text {
        @include mobile-down {
            display: none;
        }
    }
    .wrap-header {
        @include mobile-down {
            flex-flow: row nowrap;
            justify-content: space-around;
            align-items: center;
        }
    }
    .fa-calendar {
        color: $purple;
        font-size: 24px;
    }
    .container-workout {
        @include mobile-down {
            padding: 0 35px;
        }
    }
}
.meal-plan-line {
    width: 1px;
    background-color: #000;
    color: #000;
    opacity: 0.1;
}