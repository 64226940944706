.comingsoon {
    background: transparent url('../../upload/bg-comingsoon.jpg') center/cover no-repeat;
    height: 100vh;
    padding: 20px 0;
    position: relative;
    @include mobile-down {
        min-height: 100vh;
        height: 100%;
    }
    .logo {
        @include max-width-center(99px);
        @include small-mobile {
            @include max-width-center(60px);
            img {
                max-height: 60px;
            }
        }
    }
    .slogan {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        @include small-mobile {
            padding: 0 10px;
        }
        @at-root .box-skew {
            position: absolute;
            top: 0;
            left: 50%;
            max-width: 550px;
            width: 100%;
            height: 260px;
            transform: translate3d(-50%, -50%, 0);
            @include mobile-down {
                max-width: 300px;
                height: 200px;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-width: 10px;
                border-style: solid;
                border-image-source: linear-gradient(97.31deg, #21285D -48.38%, #49286F 51.62%);
                border-image-slice: 20;
                z-index: 1;
                transform: skew(-20deg, 0deg);
            }
        }
        h3 {
            z-index: 5;
            font: normal 72px/ 86px $font-BranB;
            font-style: italic;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100vw - 60px);
            transform: translate(-50%, -50%);
            @include tablet-down {
                font-size: 60px;
                line-height: 65px;
            }
            @include mobile-down {
                font-size: 45px;
                line-height: 50px;
            }
            @include small-mobile {
                font-size: 38px;
                line-height: 50px;
            }
        }
    }
    .wrap-form {
        position: absolute;
        top: 75%;
        left: 50%;
        max-width: 430px;
        width: 100%;
        transform: translate3d(-50%, -50%, 0);
        background: linear-gradient(97.31deg, #21285D -48.38%, #49286F 51.62%);
        padding: 12px 40px 15px;
        @include mobile-down {
            padding-right: 15px;
            padding-left: 15px;
            max-width: 350px;
        }
        form {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        input[type="text"] {
            @include reset-input;
            @include reset-autofill(#FFF);
            background-color: transparent;
            text-transform: uppercase;
            opacity: 0.5;
            font-size: 16px;
            letter-spacing: 0.3px;
            text-overflow: ellipsis;
            margin-right: 10px;
            color: #FFF;
            width: 100%;
            border-bottom: 1px solid #FFFFFF;
            padding: 6px 0;
            @include placeholder {
                color: #FFF;
                transition: opacity 0.5s ease;
            }
            &:focus {
                @include placeholder {
                    opacity: 0;
                }
                &+input[type="submit"] {
                    opacity: 1;
                }
            }
            @include mobile-up {
                font-size: 12px;
            }
        }
        input[type="submit"] {
            @include reset-input;
            font: normal 16px / normal $font-BranB;
            background-color: transparent;
            opacity: 0.5;
            text-transform: uppercase;
            color: #FFF;
            opacity: 0.5;
            cursor: pointer;
            transition: all 0.5s ease;
            &:hover {
                opacity: 1;
            }
        }
    }
    .social {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        max-width: 150px;
        transform: translate3d(-50%, -50%, 0);
        span {
            text-align: center;
            display: block;
            margin-bottom: 15px;
            font-size: 12px;
            opacity: 0.5;
            text-transform: uppercase;
            color: #fff;
        }
        .list-social {
            list-style: none;
            margin-left: 0;
            margin-bottom: 0;
            padding-left: 0;
            justify-content: space-evenly;
            display: flex;
            li {
                transition: opacity 0.5s ease;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

//FORM COMING SOON
.gform_wrapper {
    margin: 0 !important;
    .gform_validation_container {
        display: none;
    }
}

.wrap-form {
    .top_label .gfield_label {
        display: none !important;
    }
    .gform_fields {
        padding: 0 !important;
        margin: 0 !important;
    }
    .gfield {
        list-style: none;
    }
    .validation_error {
        display: none;
    }
    .gfield_description {
        font-size: 12px;
        color: #fff;
        padding-top: 5px;
        opacity: 0.5;
    }
    .gform_body {
        width: 100%;
    }
    .gform_footer {
        padding: 0 !important;
        margin: 0 !important;
    }
    .gform_footer input.button,
    .gform_footer input[type=submit],
    .gform_page_footer input.button,
    .gform_page_footer input[type=submit] {
        margin: 0 !important;
    }
    .gform_confirmation_message_1.gform_confirmation_message {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        opacity: 0.5;
    }
    .gform_ajax_spinner {
        width: 20px;
        position: absolute;
        top: 15px;
        right: 10px;
    }
}