section.the-banner-home {
    position: relative;
    .icon-interaction {
        position: absolute;
        right: 30px;
        z-index: 2;
        bottom: 20px;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        @include tablet-up {
            right: 60px;
        }
        &>li {
            margin-bottom: 24px;
            a {
                display: block;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f2f2f2;
                transition: all .3s ease;
                &:hover {
                    opacity: .9;
                }
                @include small-mobile-up {
                    width: 48px;
                    height: 48px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .banner-text {
        padding-top: 150px;
        padding-bottom: 20px;
        @include tablet-up {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 100vh;
            display: flex;
            align-items: center;
        }
    }
    .wrap-banner {
        max-width: 575px;
        padding-right: 15px;
        @include tablet-up {
            max-width: 315px;
        }
    }
    .title {
        font: italic 40px / 1em $font-br;
        color: $blueblack;
        text-transform: uppercase;
        margin-bottom: 40px;
        word-break: break-word;
        @include tablet-up {
            font: italic 40px / 52px $font-br;
        }
    }
    .des {
        color: $black-acc;
        letter-spacing: 0.2px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 14px;
        @include tablet-up {
            margin-bottom: 80px;
        }
    }
    .des2 {
        font-family: $font-Rr;
        font-size: 12px;
        color: #333333;
        letter-spacing: 0.3px;
        line-height: 18px;
        font-weight: 600;
        margin-top: 20px;
    }
    .img-skew {
        padding-bottom: 50vh;
        clip-path: polygon(17.5vh 0%, calc(100% - 20px) 0%, calc(100% - 17.5vh - 20px) 100%, 0% 100%);
        @include tablet-up {
            height: 100vh;
            clip-path: polygon(17.5vh 0%, calc(100% - 20px) 0%, calc(100% - 17.5vh - 20px) 100%, 0% 100%);
        }
        @media screen and (min-width: 768px) and (max-height: 768px) {
            height: 120vh;
            clip-path: polygon(20.5vh 0%, calc(100% - 20px) 0%, calc(100% - 20.5vh - 19px) 100%, 0% 100%);
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($purple, 0.05);
            transition: all 0.4s ease;
        }
        img {
            width: 100%;
            display: none;
        }
        &:hover {
            &:before {
                opacity: 0;
            }
        }
    }
    .col-lg-8 {
        @include tablet-down {
            padding-left: 0;
        }
    }
    .item-banner-home {
        overflow: hidden;
        position: relative;
        height: 50vh;
        @include tablet-up {
            height: 100vh;
        }
        @media screen and (min-width: 768px) and (max-height: 768px) {
            height: 120vh;
        }
    }
    svg {
        margin: 0;
        padding: 0;
        height: 100vh;
        width: 100%;
        opacity: 0;
    }
    .slider-banner-home {
        position: relative;
        margin-top: 10px;
        @include tablet-up {
            margin-top: 0;
        }
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 100vh 17.5vh 0 0;
            border-color: $bg-gray transparent transparent transparent;
            left: -1px;
            top: 0;
            z-index: 2;
            opacity: 0;
            @include tablet-up {
                opacity: 1;
            }
        }
        &.detect-ie {
            .img-skew {
                opacity: 0;
                display: none;
            }
            svg {
                opacity: 1;
            }
        }
        .owl-stage {
            transition: 0.8s !important;
        }
    }
    .control-slider {
        padding-right: 15px;
        padding-top: 80px;
        text-transform: lowercase;
        @include tablet-up {
            position: absolute;
            bottom: 50px;
            left: 0;
            width: 100%;
            padding-top: 0;
            padding-right: 20px;
            padding-left: 15px;
        }
        @include desktop-up {
            bottom: 50px;
        }
    }
    .btn-prev {
        position: absolute;
        bottom: 34px;
        text-transform: uppercase;
        &.disable {
            &:before {
                background-color: $gray;
            }
        }
        &:before {
            background-color: $black-acc;
            width: 135px;
            @include break-min(1025px) {
                width: 175px;
            }
        }
    }
    .btn-next {
        position: absolute;
        bottom: 0;
        right: 30px;
        text-transform: uppercase;
        color: $black-acc;
        @include tablet-up {
            right: 15px;
        }
        &.disable {
            &:before {
                background-color: $gray;
            }
        }
        &:before {
            background-color: $black-acc;
            width: 135px;
            @include break-min(1025px) {
                width: 175px;
            }
        }
    }
}
// THE-HOME-INS
section.the-home-ins {
    .container {
        padding-top: 27px;
        padding-bottom: 25px;
        @include tablet-up {
            padding-bottom: 65px;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
        @include mobile-down {
            font-size: 14px;
        }
    }
    .title-ins {
        margin-bottom: 60px;
        font-family: $font-Pop;
        font-weight: 500;
        span {
            color: $greysecond;
        }
        @include mobile-down {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .follow {
        font-style: 12px;
        position: relative;
        padding-right: 30px;
        font-family: $font-Rr;
        span {
            color: #fff;
        }
        @include mobile-up {
            float: right;
            padding-right: 60px;
        }
        i {
            font-size: 24px;
            padding-left: 20px;
            position: absolute;
            right: 0;
            top: -3px;
            @include mobile-up {
                font-size: 46px;
                top: -15px;
            }
        }
    }
}
.item-ins {
    position: relative;
    transition: transform 0.4s ease;
    display: block;
    margin-bottom: 80px;
    transform: translate3d(0, 20px, 0);
    @include tablet-up {
        transform: translate3d(0, 0, 0);
        margin-bottom: 0;
    }
    &:hover {
        transform: translate3d(0, 20px, 0);
        .des {
            opacity: 1;
        }
        .user {
            top: -60px;
        }
    }
    .img-drop-ins {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($purple, 0.2);
            transition: all 0.4s ease;
            z-index: 1;
        }
    }
    .des {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        font-size: 12px;
        color: #fff;
        overflow: auto;
        background-image: linear-gradient(-180deg, rgba(73, 40, 111, 0.00) 0%, #49286F 98%);
        transition: opacity 0.4s ease;
        @include tablet-up {
            opacity: 0;
        }
    }
    .user {
        height: 60px;
        background: #ffffff;
        color: #000;
        display: flex;
        align-items: center;
        padding: 15px;
        transition: all 0.4s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include tablet-down {
            top: -60px;
        }
    }
    .avatar {
        width: 30px;
        margin-right: 15px;
        font-size: 12px;
        color: #333333;
        letter-spacing: 0.3px;
        .img-drop {
            border-radius: 50%;
            overflow: hidden;
        }
    }
}
.slider-ourproduct {
    @include desktop-up {
        margin-right: -60px;
    }
    .item-slider-ourproduct {
        transition: all 0.9s ease;
        &.active-hover {
            @include desktop-up {
                &:hover {
                    transform: translate3d(0, 0, 0) !important;
                }
            }
        }
    }
    //&.slider-product-top-row .owl-item.active~.owl-item .item-slider-ourproduct {
    //    @include desktop-up {
    //        //transform: translate3d(340px, 0, 0);
    //        &:hover {
    //            transform: translate3d(0 , 0, 0);
    //        }
    //    }
    //}
    //&.slider-product-second-row .owl-item.active:first-child .item-slider-ourproduct{
    //    @include desktop-up {
    //        //transform: translate3d(-410px, 0, 0);
    //        &:hover {
    //            transform: translate3d(0 , 0, 0);
    //        }
    //    }
    //}
    //&.slider-product-second-row .owl-item.active~.owl-item:not(:last-child) .item-slider-ourproduct {
    //    @include desktop-up {
    //        transform: translate3d(0, 0, 0);
    //        &:hover {
    //            transform: translate3d(0 , 0, 0);
    //        }
    //    }
    //}
    //.owl-item.active+.owl-item.active:not(:last-child) .border-hover {
        // display: none;
    //}
    .owl-item.active {
        z-index: 2;
        &+.owl-item.active {
            z-index: 1;
        }
    }
    .owl-nav {
        margin-bottom: 15px;
        padding: 0 10px;
        text-align: right;
        position: absolute;
        width: 100%;
        top: 30%;
        display: flex;
        justify-content: space-between;
        @include desktop-up {
            margin-right: 60px;
        }
        button {
            //background: transparent !important;
            height: 50px;   
            width: 50px;    
            background: rgba(0,0,0,0.2) !important;
            border-radius: 50% !important;
            &:after {
                content: '' !important;
                border: solid $blueblack;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;       
                width: 10px;
                height: 10px;         
                position: absolute;
                top: 20px;
                left: 18px;
                transform: rotate(-45deg);
            }
            &:before {
                display: none;
            }
            &.owl-prev:after {
                transform: rotate(135deg);
                left: 20px;
            }
            &.disabled {
                opacity: 0 !important;
            }
        }
    }
    .owl-dots {
        position: absolute;
        left: 45%;
        bottom: 0;
        display: inline-flex;
        @include desktop-down {
            left: 50%;
            transform: translateX(-50%);
        }
        .owl-dot {
            outline: none;
            &.active {
                span {
                    background: $purple;
                }
            }
        }
    }
    i.fa-shopping-cart {
        color: #fff;
    }
}
.slider-product-top-row {
    @include desktop-up {
        padding-left: 0px;
    }
    padding-bottom: 30px;
    .owl-prev {
        //display: none !important;
    }
    .item-slider-ourproduct {
        margin-right: 50px;
        @include small-mobile {
            margin-right: 0;
        }
    }
}
.slider-product-second-row {
    margin-bottom: 80px;
    .owl-next {
        display: none !important;
    }
    .owl-nav {
        position: absolute;
        left: 0;
        bottom: -20px;
    }
    .owl-dots {
        right: 10%;
        bottom: 0;
        left: auto;
    }
    @include desktop-up {
        .item-slider-ourproduct {
            margin-right: 50px;
        }
    }
}
.border-hover {
    content: "";
    position: absolute;
    left: 60px;
    top: 30px;
    bottom: 30px;
    width: 90%; // border: 3px $purple solid;
    z-index: 2;
    transform: skewX(-17.5deg);
    display: none;
    @include desktop-up {
        display: block;
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        box-sizing: border-box;
        transition: 0.5s;
        transform: scale(0);
    }
    &:before {
        border-bottom: 3px $purple solid;
        border-left: 3px $purple solid;
        transform-origin: 0 100%;
    }
    &:after {
        border-top: 3px $purple solid;
        border-right: 3px $purple solid;
        transform-origin: 100% 0;
    }
}
.item-ourproduct {
    display: flex;
    margin-bottom: 30px;
    position: relative;
    font-family: $font-Pop;
    @include small-mobile {
        flex-flow: column;
    }
    .border-hover {
        &:before,
        &:after {
            transform: scale(1)
        }
    }
    // &:hover {
    //     .border-hover {
    //         &:before,
    //         &:after {
    //             transform: scale(1)
    //         }
    //     }
    // }
    &:first-child {
        margin-bottom: 40px;
        @include tablet-up {
            margin-bottom: 80px;
        }
        @include desktop-up {
            margin-bottom: 30px;
        }
    }
    .img-drop {
        display: block;
        background: $gray-bg-product;
    }
    .img-product {
        width: 45.5%;
        min-width: 45.5%;
        @include desktop-up {
            //padding: 45px;
        }
        @include small-mobile {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .info-product {
        padding: 0px 25px;
        position: relative;
        z-index: 3;
        .fa-angle-right {
            color: #fff;
            font-size: 25px;
        }
        @include small-mobile {
            padding: 0;
        }
        @include desktop-up {
            padding: 60px 30px;
        }
    }
    a {
        text-decoration: none;
    }
    h4 {
        font-weight: 700;
        font-size: 19px;
        color: $blueblack;
        margin-bottom: 20px;
        position: relative;
        text-align: center;
        @include desktop-up {
            font-size: 20px;
        }
        @include small-mobile {
            margin-bottom: 20px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -12px;
            left: -12px;
            width: 24px;
            height: 3px;
            background: $blueblack;
            @include small-mobile {
                left: 0;
            }
        }
    }
    .text {
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        color: $black-acc;
        @include desktop-down {
            font-size: 15px;
        }
    }
    .btn-addcart {
        margin-top: 20px;
        transition: all 0.4s ease;
        z-index: 3;
        &:hover {
            background: $gradient;
            color: #fff;
        }
    }
}
.slider-product-second-row {
    @include desktop-up {
        .item-ourproduct {
            .info-product {
                margin-left: 90px;
            }
        }
    }
}
.list-last-news {
    justify-content: center;
}
.item-last-news {
    a {
        text-decoration: none;
        color: $black;
        &:hover {
            color: $purple;
        }
    }
    .img-drop {
        display: block;
        padding-bottom: 285/427*100%;
    }
    .text {
        text-align: center;
    }
    .title {
        font-weight: 700;
        font-size: 24px;
        margin-top: 20px;
        font-size: 20px;
        @include mobile-down {
            height: auto !important;
        }
        @include mobile-up {
            font-size: 24px;
            margin-top: 30px;
        }
    }
    .intro {
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 24px;
        margin-top: 15px;
        text-align: left;
        color: #494949;
        @include mobile-down {
            height: auto !important;
        }
    }
    .date {
        font-weight: 600;
        font-size: 12px;
        color: $grey;
        letter-spacing: 0.3px;
        line-height: 18px;
        margin-top: 15px;
        a {
            color: $grey;
        }
    }
    .cate {
        margin-top: 20px;
        a {
            font-weight: 600;
            font-size: 14px;
            color: $purple;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 50px;
            @include mobile-down {
                margin-top: 10px;
            }
        }
    }
}
// THE-FEATURE-LIST
section.the-feature-list {
    .owl-carousel {
        .owl-stage {
            @include tablet-up {
                display: flex;
                left: 2px;
            }
        }
        .owl-page {
            position: absolute;
            bottom: 70px;
            left: 50%;
            transform: translate3d(0, -50%, 0);
            font-family: $font-Pop;
            color: $greysecond;
            line-height: 25px;
            letter-spacing: 3px;
            font-weight: 300;
            font-size: 14px;
            @include tablet-down {
                display: none;
            }
            span {
                font-family: $font-Pop;
                font-weight: 300;
                font-size: 14px;
            }
        }
        .owl-dots {
            display: none;
        }
        .owl-nav {
            button {
                @include reset-input;
                text-transform: uppercase;
                cursor: pointer;
                display: block;
                position: relative;
                z-index: 9;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
                padding: 0 !important;
                width: 20px;
                height: 36px;
                background: transparent url(../img/icon-slider-prev.svg) no-repeat center left/10px auto;
                position: absolute;
                left: 0;
                top: 50%;
                @include tablet-up {
                    left: 27%;
                }
            }
            .owl-next {
                left: auto;
                right: 0;
                background: transparent url(../img/icon-slider-next.svg) no-repeat top right/10px auto;
                @include tablet-up {
                    right: 26%;
                }
            }
        }
        .owl-item {
            transition: opacity .2s ease;
        }
        .owl-center-item {
            opacity: 1;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: -10%;
                height: 100%;
                width: 1px;
                background: #E0E0E0;
            }
            &:after {
                left: auto;
                right: -10%;
            }
            .wrap-item {
                transform: translate3d(0, 0, 0);
            }
            .img-drop {
                transform: scale(1) translate3d(0, 30px, 0);
                margin-top: 0;
                &:before {
                    opacity: 1;
                    transform: translate3d(-15px, -50%, 0) skewX(-18deg);
                }
            }
            .title:after {
                opacity: 1;
            }
            .text {
                height: auto;
                opacity: 1;
            }
        }
    }
}
.home-feature-items {
    padding-top: 60px;
    padding-bottom: 60px;
    @include tablet-up {
        padding-top: 80px;
        padding-bottom: 194px;
        .wrap-item {
            transform: translate3d(0, 50px, 0);
            transition: all 0.5s ease;
        }
    }
    .img {
        @include max-width-center(130px);
        margin-bottom: 40px;
        @include tablet-up {
            margin-bottom: 80px;
        }
    }
    .img-drop {
        padding-top: 155/174*100%;
        transform: scale(1) translate3d(0, 0, 0);
        transition: all 0.5s ease;
        @include tablet-up {
            transform: scale(1) translate3d(0, calc(55% - 30px), 0);
        }
        img {
            object-fit: contain;
            z-index: 1;
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 145px;
            width: 70px;
            background: $bg-gray;
            transform: translate3d(0, -50%, 0) skewX(-18deg);
        }
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 165px;
            width: 70px;
            background: transparent;
            border: 1px solid $purple;
            transform: translate3d(-15px, -50%, 0) skewX(-18deg); // -15px
            transition: all 1s ease;
            @include tablet-up {
                opacity: 0;
                transform: translate3d(0, -50%, 0) skewX(-18deg);
            }
        }
    }
    .title {        
        text-align: center;
        text-transform: capitalize;
        position: relative;
        margin-bottom: 40px;
        font-family: $font-Pop;
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
        color: $blueblack;
        &:after {
            content: "";
            position: absolute;
            left: calc(50% - 12px);
            bottom: -10px;
            height: 3px;
            width: 24px;
            background: $purple;
            transition: all 0.8s ease;
            @include tablet-up {
                opacity: 0;
            }
        }
        a {
            color: $blueblack;
            text-decoration: none;
        }
    }
    .text {
        @include max-width-center(400px);
        text-align: center;
        @include tablet-up {
            opacity: 0; // height: 0;
            overflow: hidden;
            transition: all 0.8s ease;
        }
    }
}
.owl-theme .owl-nav [class*=owl-]:hover {
    color: #333333;
}
.des-page {
    color: #21285D;
    font-family: $font-Rr !important;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 29px !important;
}
.the-home-lastnews {
    text-align: center;
    .title-page {
        color: $title-home;
        font-family: $font-BranB;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0.9px;
        line-height: 52px;
    }
    .des-page {
        color: #21285D;
        font-family: $font-Rr !important;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 29px !important;
    }
    .item-last-news {
        .title {
            color: $blueblack;
            font-family: $font-Pop;
            font-size: 24px;
            font-weight: 600;
            line-height: 35px;
            text-align: center;
        }
    }
    .intro {
        color: $black-acc;
        font-family: $font-Pop;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: justify;
    }
    .date {
        margin: 20px 0;
    }
    .cate {
        a {
            color: $headingtext;
            font-family: $font-Pop;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
        }
    }
    .btn-edit {
        margin-top: 50px;
        color: $greysecond;
        &:hover {
            color: #fff;
        }
    }
}
.the-home-ourproduct {
    .owl-nav {
        button {
            text-transform: lowercase !important;
        }
    }
    .owl-prev,
    .owl-next {
        color: $black-acc !important;
    }
    .btn-edit.gradient-corner {
        span {
            color: $greysecond;
        }
    }
}
/* scss  */
