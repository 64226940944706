/* MIXIN*/

@mixin max-desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin desktop-down {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: 768px) and (max-width: 1199px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin tablet-down {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin tablet-up {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: 576px) and (max-width: 767px) {
        @content;
    }
}

@mixin mobile-down {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mobile-up {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin mobile-tablet {
    @media (min-width: 576px) and (max-width: 991px) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: 575px) {
        @content;
    }
}

@mixin small-mobile-up {
    @media (min-width: 576px) {
        @content;
    }
}

// gutters
@mixin space-col($pixel) {
    margin-left: -$pixel/2;
    margin-right: -$pixel/2;
    @content;
    >.col,
    >[class*="col"] {
        padding-left: $pixel/2;
        padding-right: $pixel/2;
    }
}

@mixin make-col($size, $columns: -1) {
    @if($columns !=-1) {
        flex: 0 0 percentage($size / $columns);
        max-width: percentage($size / $columns);
    }
    @else {
        flex: 0 0 percentage(1/$size);
        max-width: percentage(1/$size);
    }
}

@mixin make-row($space: 15px) {
    display: flex;
    flex-wrap: wrap;
    @if($space !=15px) {
        margin-right: -$space;
        margin-left: -$space;
    }
    @else {
        margin-right: -15px;
        margin-left: -15px;
    }
}

@mixin max-width-center($pixel) {
    max-width: $pixel;
    margin-left: auto;
    margin-right: auto;
}

@mixin break-max($pixel) {
    @media (max-width: $pixel) {
        @content;
    }
}

@mixin break-min($pixel) {
    @media (min-width: $pixel) {
        @content;
    }
}

@mixin break($pixel1, $pixel2) {
    @media (min-width: $pixel1) and (max-width: $pixel2) {
        @content;
    }
}

/* FORM */

@mixin reset-input {
    -webkit-appearance: none;
    border: 0;
    border-radius: 0; // Iphone5
    outline: 0;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
}

@mixin reset-autofill($color: #000) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background 5000s ease-in-out 0s;
        -webkit-text-fill-color: $color;
    }
}