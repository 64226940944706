.container-small {
    max-width: 920px;
}

section.cart-review {
    padding-bottom: 50px;
}

.cart-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    &>li {
        display: flex;
        padding: 12px 20px;
        @include small-mobile {
            padding: 12px 15px;
        }
        &:first-child {
            padding-top: 0;
        }
        &.remove-item-block {
            background: #F2F2F2;
            display: none;
            font-size: 14px;
            margin: 12px 0;
            span {
                font-weight: 700;
                font: 700 16px/ normal $font-br;
                margin-right: 10px;
            }
        }
    }
}

.also-bought,
.wishlist {
    .title-section-cart {
        color: #333;
    }
    .add-to-cart {
        display: none;
    }
    .size-option {
        margin-bottom: 0;
    }
    .product-slide-block:hover {
        .hover-toggle {
            @include tablet-up {
                height: 155px;
            }
        }
    }
}

.cart-review-heading {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 20px;
    font-family: $font-br;
}

.cart-review-img {
    width: 92px;
    min-width: 92px;
    margin-right: 20px;
    @include small-mobile {
        width: 72px;
        min-width: 72px;
        margin-right: 10px;
    }
    .img-drop {
        border: 1px solid #F2F2F2;
    }
    img {
        object-fit: contain;
    }
}

.cart-review-info {
    flex: auto;
}

.cart-review-top,
.cart-review-middle,
.cart-review-bottom {
    display: flex;
    padding-bottom: 15px;
    @include small-mobile {
        flex-wrap: wrap;
        padding-bottom: 10px;
    }
}

.cart-review-top {
    font-family: $font-br;
    font-weight: 700;
    letter-spacing: 0.2px;
}

.cart-review-bottom {
    a {
        color: #0066C0;
        font-size: 12px;
    }
}

.remove-item {
    margin-left: auto;
}

.item-size,
.item-qty {
    width: 150px;
    margin-right: 15px;
    font-size: 14px;
    @include small-mobile {
        width: 130px;
    }
    label {
        margin-bottom: 0;
    }
}

.item-size {
    font-weight: 700;
    label {
        font-weight: 600;
        display: inline-block;
        margin-right: 5px;
    }
}

.edited-select {
    border: 0;
    appearance: none;
    outline: none;
    font-weight: 700;
    width: 80px;
    padding-left: 5px;
    cursor: pointer;
    background: transparent url('../img/arrow-down.svg') right 45% center/ 15px 7px no-repeat;
    &.select-qty {
        width: 60px;
    }
}

.item-name {
    padding-right: 15px;
}

.item-price {
    margin-left: auto;
    @include small-mobile {
        width: 100%;
        margin-left: 0;
    }
}

.cart-review-total {
    background: #F2F2F2;
    padding: 22px 20px 24px;
    @include small-mobile {
        padding: 12px 15px 14px;
    }
    .review-total-box {
        width: 100%;
        max-width: 223px;
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .btn-edit {
            width: 100%;
            font: 600 12px/ normal $font-Rr;
            transition: all .3s ease;
            padding: 15px 25px;
            &:hover {
                opacity: .8;
            }
        }
    }
    .review-total-button-group {
        text-align: right;
        .btn-edit {
            width: 100%;
            max-width: 223px;
            font: 600 12px/ normal $font-Rr;
            transition: all .3s ease;
            padding: 15px 25px;
            &:hover {
                opacity: .8;
            }
            &.btn-continue-shopping {
                background-repeat: $text-gray;
                border: solid 1px $grey;
                font-weight: 700;
                color: $black;
                margin-right: 15px;
                @include small-mobile {
                     margin-right: 0px;
                }
            }
            @include small-mobile {
                margin-bottom: 10px;
            }
        }
    }
}

.review-total-title {
    font-weight: 700;
    font-family: $font-br;
    margin-bottom: 10px;
}

.review-total-price {
    margin-left: auto;
    font-size: 24px;
    font-family: $font-br;
    margin-bottom: 10px;
    font-weight: 700;
}

.title-section-cart {
    font-size: 16px;
    font-family: $font-br;
    color: #4C3274;
    font-weight: 700;
    margin-bottom: 0;
    padding-right: 15px;
}

section.wishlist {
    padding-bottom: 20px;
    .container {
        border-bottom: 1px solid rgba(#A7AAAA, .1);
    }
    .featured-products {
        &>.d-flex {
            padding-bottom: 20px;
            @include mobile-up {
                align-items: center;
            }
        }
    }
    .product-slide-block .color-product {
        @include tablet {
            max-width: 55px;
            height: 55px;
        }
    }
    .product-slide-block {
        margin-bottom: 30px;
    }
}

section.also-bought {
    .featured-products {
        &>.d-flex {
            padding-bottom: 20px;
            @include mobile-up {
                align-items: center;
            }
        }
    }
    .product-slide-block .color-product {
        @include tablet {
            max-width: 55px;
            height: 55px;
        }
    }
    .product-slide-block {
        margin-bottom: 30px;
    }
}

//CART EMPTY STYLE
section.cart-empty {
    margin-bottom: 30px;
    .container {
        border-bottom: 1px solid rgba(#A7AAAA, .1);
        padding-bottom: 70px;
        @include small-mobile {
            padding-bottom: 40px;
        }
    }
    .img-bag {
        width: 175px;
        margin-right: 90px;
        img {
            max-width: 100%;
        }
        @include small-mobile {
            margin-right: 0;
            width: 120px;
        }
    }
}

.cart-empty-holder {
    display: flex;
    @include max-width-center(650px);
    @include small-mobile {
        flex-direction: column;
        align-items: center;
    }
}

.cart-empty-text {
    padding-top: 50px;
    @include small-mobile {
        padding-top: 30px;
    }
    h6 {
        font: 700 16px/ normal $font-br;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 70px;
        @include small-mobile {
            margin-bottom: 40px;
        }
    }
}
.item-extra-att {
    label {
        text-transform: capitalize;
    }
}
.extra-attr {
    text-transform: capitalize;
}
#show-cart-content {
    .go-cart-row {
        .btn-edit.white {
            margin-top: 0px;
        }
        
    }
}
.woocommerce-cart {
    .cart-review {
        a.remove-item {
            color: $pink;
            img {
                padding-right: 2px;
                padding-bottom: 2px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}