.news-custom {
    padding-bottom: 0;
    .img-drop {
        margin-bottom: 0;
    }
    .container {
        max-width: 1024px;
    }
}

.news-content {
    padding: 50px 0;
    .container {
        position: relative;
        .btn-edit {
            position: absolute;
            bottom: -75px;
            right: 80px;
            @include tablet-down {
                bottom: -85px;
                right: 15px;
            }
        }
        p {
            line-height: 24px;
        }
        .wrap-column {
            @include tablet-up {
                column-count: 2;
                column-gap: 100px;
                column-fill: balance;
            }
        }
    }
    blockquote {
        margin: 65px 0;
        text-align: center;
        @include max-width-center(900px);
        padding: 0 40px;
        font: 600 24px / 30px $font-Rr;
        color: $purple;
        position: relative;
        @include tablet-down {
            font-size: 18px;
            line-height: 28px;
        }
        &:after,
        &:before {
            content: '';
            background: url(../img/news-blockquote-1.svg) no-repeat center / 100%;
            position: absolute;
            width: 28px;
            height: 25px;
            @include tablet-down {
                width: 20px;
                height: 22px;
            }
        }
        &:after {
            top: -8px;
            left: 0;
        }
        &:before {
            right: 0;
            bottom: -8px;
            transform: rotate(180deg);
        }
    }
    .img-full-right {
        padding-top: 550/1500*100%;
        margin-bottom: 50px;
    }
}

//***************//
// NEWS ARCHIVE //
//*************//
// THE-NEWS-CONTENT
section.the-news-tuanh {
    .wrap-container {
        @include max-width-center(1100px);
        @include break-min(2001px) {
            @include max-width-center(65vw);
        }
    }
    a {
        text-decoration: none;
        &:hover {
            color: $black;
        }

    }
    .date.purple {
        a {
            color: #49286f;
            margin-bottom: 5px;
            display: inline-block;
        }
    }
    .title-page {
        display: block;
        @include mobile-down {
            font-size: 24px;
        }
    }
    .text-events {
        margin-bottom: 5px;
        @include mobile-up {
            margin-bottom: 45px;
        }
    }
    .date {
        @include mobile-down {
            margin-bottom: 30px;
        }
    }
    .img-news {
        width: 100%;
        img {
            width: 100%;
        }
        @include mobile-down {
            @include max-width-center(380px);
        }
    }
    .col-md-4,
    .col-md-5,
    .col-md-7,
    .col-md-8 {
        display: flex;
        align-items: center;
    }
    .row {
        margin-bottom: 40px;
        @include mobile-up {
            margin-bottom: 80px;
        }
    }
    .wrap-text {
        @include mobile-up {
            position: relative;
            .border-hover {
                top: -10%;
                height: auto;
                bottom: -10%;
                left: -20%;
                width: auto;
                right: -80%;
                z-index: 1;
                &~* {
                    position: relative;
                    z-index: 1;
                }
            }
            &:hover {
                .border-hover {
                    &:before,
                    &:after {
                        transform: scale(1)
                    }
                }
            }
        }
    }
}

.img-with-logo {
    @include mobile-up {
        .wrap-text {
            text-align: right;
            padding-right: 70px;
            max-width: 380px;
            margin-left: auto;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                background: transparent url("../img/news-logo-simple.svg") no-repeat center right / auto 100%;
                width: 200%;
                height: 180%;
                right: 30%;
                top: -50%;
                z-index: -1;
            }
        }
        .text-events {
            margin-right: 0;
            &:before {
                left: 100%;
            }
        }
    }
}