.woocommerce-error {
    display: none !important;
}
.woocommerce-message {
    padding: 15px;
    color: #fff;
    width: 100%;
}
.the-myaccount-wrapper {
    .account-settings {
        color: $greysecond;
        border: 3px solid $blueblack;
        padding: 10px 45px;
        display: inline-block;
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 16px;
        width: 100%;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        &:hover {
            text-decoration: none;
        }
        &.add-info {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
            text-align: center;
        }
    }
    .add-about-me {
        color: $gray-acc;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $text-gray;
    .my-account-sidebar-link {
        li {
            margin-bottom: 10px;
        }
    }
    .address-edit {
        .action-edit {
            &.hide {
                display: none;
            }
        }
        .action-submit {
            display: none;
            &.active {
                display: block;
            }
        }
        .edit-field-item {
            margin-bottom: 20px;
            label {
                &.hide {
                    display: none;
                }
            }
            input {
                display: none;
                &.active {
                    display: block;
                }
            }
        }
        .action-submit {
            display: none;
            &.active {
                display: block;
            }
        }
    }
    .address-edit-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        h4 {
            font-size: 16px;
            color: $greysecond;
        }
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .row {
        [class*="col-"] {
            &:last-child {
                @include tablet-down {
                    order: 1;
                }
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
    &.my-accounts {
        background-color: $white;
        padding: 40px 0;
        .the-myaccount-sidebar {
            &.is_stuck {
                margin-top: 25px;
            }
            .box-info {
                padding-top: 0;
            }
        }
    }
    .my-account-settings {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        background-color: $white;
        .form-row.field-error {
            margin-bottom: 5px;            
        }
        h3 {
            margin-bottom: 20px;
        }
        .form-row-upload-user-avatar {
            position: relative;
        }
        .form-label-require-field {
            padding: 30px 0;
        }
        .woocommerce-password-strength {
            text-align: left;
            padding: 10px 0;
        }
        &.edit-setting-address {
            max-width: 425px;
            font-family: Poppins;
            .woocommerce-EditAccountForm {
                label {
                    color: $black-acc;
                }
            }
        }
        .woocommerce-EditAccountForm {
            max-width: 430px;            
            .wrap-account-setting-two-columns {
                display: flex;
                justify-content: space-between;
                .wrap-first-name,
                .wrap-last-name,
                .woocommerce-city,
                .woocommerce-company-name {
                    width: calc(50% - 10px);
                }
                .select-country {
                    background: none;
                    border: 1px solid $grey;
                }
            }
            .field-billing-warp {
                .col-sm-12, .col-sm-6 {
                    &.row-error {
                        margin-bottom: 5px;
                        input {
                            border: 1px solid red;
                        }
                        .msg-error {
                            color: #D0021B;
                            font-size: 12px;
                        }
                    }
                }
            }
            
        }
    }
    .my-account-sidebar {
        a {
            color: $headingtext;
            &.active {
                color: $active-account;
            }
        }
    }
    .the-myaccount-sidebar {
        font-family: "Poppins";
        .box-info {
            padding: 25px;
            @include tablet-down {
                padding: 0 0 25px 0;
            }
            background-color: #FFF;
            font-size: 14px;
            border-radius: 4px;
            h3 {
                font-size: 16px;
                margin-bottom: 13px;
                color: $greysecond;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }
            @include max-desktop {
                padding: 45px 38px;
            }
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
        .title-side {
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            color: $black-acc;
            margin-bottom: 15px;
        }
        .list-info {
            margin-bottom: 25px;
            li {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
            i {
                position: relative;
                top: 2px;
            }
            span {
                display: inline-block;
                margin-left: 15px;
                color: $black-acc;
            }
        }
        dl {
            dt {
                font-weight: 700;
            }
        }
        .wrap-friend {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-side {
                margin-bottom: 0;
            }
        }
        .list-ava {
            position: relative;
            &.list-ava-margin {
                margin-top: 25px;
            }
            .ven-loading {
                i.spinner {
                    width: 60px;
                    height: 60px;
                }
            }
            li {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                a {
                    display: flex;
                    align-items: center;
                    color: $black-acc;
                }
                .ava {
                    flex-basis: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        border-radius: 50%;
                        width: 100%;
                    }
                }
                .name {
                    margin-left: 15px;
                }
            }
        }
        .fb-group {
            border-radius: 4px;
            background-color: #FFF;
            .wrap-white {
                padding: 25px;
                @include max-desktop {
                    padding: 25px 40px;
                }
            }
            .title-group {
                color: $gray-acc;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0;
            }
            .member {
                color: #9B9B9B;
                font-weight: 500;
                font-size: 14px;
            }
            .list-img {
                .row {
                    @include space-col(5px);
                    [class*="col"] {
                        margin-top: 5px;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .the-myaccount-content {
        .edit-address-shipping-empty {
            font-size: 11px;
        }
        .fa-facebook-official {
            font-size: 24px;
            color: #4460A0;
        }
        h3,
        legend {
            color: $purple;
            font-weight: 600;
            font-size: 20px;
            font-family: "Poppins";
            @include mobile-up {
                font-size: 24px;
            }
        }
        .button-wap {
            display: flex;
            justify-content: space-between;
        }
        .woocommerce-Button {
            //background-color: $title-home;
            background: linear-gradient(to right, #21285D , #49286F);
            border: none;
            padding: 12px 30px;
            width: calc(50% - 10px);
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            &:hover {
                cursor: pointer;
            }
            &.btn-cancel {
                background-color: $text-gray;
                color: $black;
                border: 1px solid $grey;
                width: calc(50% - 10px);
                text-align: center;
                &:hover {
                    text-decoration: none;
                }
            }
            &.btn-save-settings-edit {
                
            }
        }
        .my-account-connect-socials {
            max-width: 425px;
            border-top-color: $grey;
            border-top-style: solid;
            border-width: 1px;
            padding-top: 32px;
            margin-top: 35px;
            @include tablet-down {
                max-width: 100%;
            }
            h3 {
                color: $greysecond;
                font-size: 16px;
            }
            .socials {
                padding: 20px 0;
                margin-bottom: 40px;
            }
            a {
                &.delete-account {
                    color: $black-acc;
                    font-size: 14px;
                }
            }
            .items {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: stretch;
                a {
                    font-size: 12px;
                    color: $black;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    span {
                        margin-left: 10px;
                    }
                }
            }
        }
        .woocommerce-EditAccountForm {
            p {
                &.label-error {
                    color: #D0021B;
                    font-size: 12px;
                }
            }
            .woocommerce-billing-city {
                margin-left: 20px;
                @include mobile-down {
                    margin-left: 0;
                    width: 100%;
                }
            }
            input {
                &[name='gender'] {
                    float: left;
                    margin: 0 12px;
                    &[value='male'] {
                        margin-left: 0;
                    }
                }
                &[name='city_user'] {
                    min-width: 203px;
                }
            }
            .woo-form-gender {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                input {
                    display: none;
                    &:checked + label {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                label {
                    text-transform: capitalize;
                }
                .select-gender {
                    width: auto;
                    margin-right: 40px;
                    position: relative;
                    padding-left: 25px;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 1px solid $gray-select;
                    }
                    &:after {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        left: 2px;
                        top: 5px;
                        width: 8px;
                        height: 8px;
                        background: $color-border-select;
                        border-radius: 50%;
                    }
                }
            }
            .wrap-select {
                overflow: hidden;
                position: relative;
                &:after {
                    content: "\f107";
                    font-family: FontAwesome;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 1;
                    height: 100%;
                    pointer-events: none;
                    font-size: 24px;
                    color: $black;
                }
            }
            select {
                height: 46px;
                float: left;
                &[name='day_birthday'],
                &[name='year_birthday'],
                &[name='billing_phone_code'] {
                    min-width: 92px;
                }
                &[name='month_birthday'] {
                    min-width: 203px;
                }
                &.dropdown {
                    height: 47px;
                    padding: 10px 0 10px 15px;
                    border: 0;
                    font-size: 15px;
                    width: 100%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    font-family: Poppins;
                    background: linear-gradient(180deg, #EEEEEE 0%, $gray-bg 100%);
                    
                }
            }
            label {
                width: 100%;
                color: $grey;
                font-size: 12px;
                text-transform: uppercase;
            }
            .form-select-birthday {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                span {
                    &.form-row-list {
                        float: left;
                    }
                    &.wrap-select {
                        overflow: hidden;
                        position: relative;
                        border: 1px solid $grey;
                        &-date,
                        &-year {
                            width: calc(25% - 10px);
                        }
                        &-month {
                            width: calc(50% - 20px);
                        }
                        @include mobile-down {
                            width: 100%;
                            margin-bottom: 15px;
                            margin-left: 0;
                        }
                        &.wrap-no-margin {
                            margin-left: 0;
                            &.no-margin {
                                margin-right: 0;
                            }
                        }
                        select {
                            background: linear-gradient(180deg, #EEEEEE 0%, $gray-bg 100%);
                            &[name='day_birthday'] {
                                margin-left: 0;
                            }
                        }
                        &:after {
                            content: "\f107";
                            font-family: FontAwesome;
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            z-index: 1;
                            height: 100%;
                            pointer-events: none;
                            font-size: 24px;
                            color: $black;
                        }
                    }
                }
            }
            .woocommerce-form-phone {
                display: flex;
                flex-wrap: wrap;
            }
            .woocommerce-Input {
                min-height: 46px;
                padding: 10px;
                border: 1px solid $grey;
                color: $black-acc;
                width: 100%;
                @include tablet-down {
                    width: 100%;
                    max-width: 100%;
                }
                font-family: Poppins;
                &[name='billing_phone'] {
                    max-width: 338px;
                    @include mobile-down {
                        max-width: 100%;
                        width: 100%;
                    }
                }
                &.woo-errors {
                    border: 1px solid red;
                }
                &[name='billing_city'] {
                    min-width: 87%;
                }
            }
            .woo-form-locations {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 15px;
                .woocommerce-form-row {
                    &:first-child {
                        @include mobile-down {
                            max-width: 100%;
                        }
                        &.woocommerce-city {
                            @include mobile-down {
                                width: 100%;
                                max-width: 100%;
                            }
                            .wrap-select {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        @include tablet-up {
            margin-bottom: 0;
        }
        .title-medium {
            color: $black-acc;
            font-size: 24px;
            font-weight: 500;
        }
        .wrap-info-account {
            background-color: #FFF;
            padding-left: 15px;
            padding-right: 15px;
            position: absolute;
            width: 100%;
            z-index: 100;
            transition: all 0.5s ease;
            @include tablet-up {
                margin-left: -28px;
                padding-left: 30px;
            }
            @include small-mobile {
                left: 0;
                padding-left: 30px;
                padding-right: 20px;
            }
            &.fixed {
                position: fixed;
                top: 88px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                animation: slideDown 0.5s ease;
                @keyframes slideDown {
                    from {
                        top: 0;
                    }
                    to {
                        top: 88px;
                    }
                }
                .btn-logout {
                    right: 30px;
                }
            }
            .wrap-info {
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                align-items: center;
                .ava {
                    margin-right: 20px;
                    flex-basis: 82px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
                .name {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 24px;
                    text-transform: uppercase;
                    color: #F5A623;
                }
                .since {
                    color: $gray-acc;
                    font-size: 14px;
                }
            }
            .btn-logout {
                position: absolute;
                right: 55px;
                top: 50%;
                width: 56px;
                height: 56px;
                background-color: $gray-acc;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: all 0.5s ease;
                @include tablet-up {
                    right: 40px;
                } 
                @include small-mobile {
                    right: 25px;
                }               
                &:hover {
                    opacity: 0.7;
                }
                &.return-your-profile {
                    width: auto;
                    background: transparent;
                    color: $black-acc;
                    font-size: 14px;
                    line-height: 24px; 
                    &:hover {
                        color: $black-acc;
                        opacity: 1;
                    }
                    @include small-mobile {
                        height: auto;
                        position: inherit;
                        justify-content: left;
                        margin-top: 15px;
                        margin-left: 16px;
                    }
                    span {
                        position: relative;
                        &:before {
                            content: " ";
                            width: 11px;
                            height: 11px;
                            position: absolute;
                            top: 7px;
                            left: -16px;
                            border: solid $black-acc;
                            border-width: 0 1px 1px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(135deg);
                            -webkit-transform: rotate(135deg);
                        }
                    }
                    
                }
            }
        }
        .section-tab-content {
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 35px;
            @include tablet-up {
                padding-left: 0;
                margin-bottom: 55px;
            }
            .tab-content {
                @include tablet-up {
                    margin-right: 40px;
                }
                a {
                    text-decoration: none;
                }
            }
        }
        .list-ul-tab {
            margin-bottom: 25px;
            display: none;
            @include tablet-up {
                display: block;
            }
            a {
                color: #D1D1D1;
                text-transform: uppercase;
                font-weight: 600;
                text-decoration: none;
                position: relative;
                display: inline-block;
                &:not(:last-child) {
                    margin-right: 55px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #F5A623;
                    opacity: 0;
                    transition: all 0.5s ease;
                }
                &:hover,
                &.active {
                    color: #F5A623;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        .tab-content {
            .item-content {
                background: #FFFFFF;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                margin-bottom: 22px;
                padding: 25px;
                transition: all 0.5s ease;
                &:hover {
                    background-color: #4C3274;
                    .wrap-title {
                        span,
                        h3 {
                            color: #FFF;
                        }
                    }
                    .desc {
                        color: rgba(#FFF, 0.75);
                    }
                }
                .wrap-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    h3 {
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                    span {
                        color: $gray-acc;
                        font-weight: 400;
                        font-size: 14px;
                    }
                }
                .desc {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: $gray-acc;
                }
            }
            .row {
                @include space-col(22px);
            }
        }
        .link-event {
            position: absolute;
            right: 15px;
            top: 150px;
            color: #D1D1D1;
            text-transform: uppercase;
            @include tablet-up {
                top: 180px;
                right: 40px;
            }
        }
        .wrap-slider {
            position: relative;
            .owl-nav,
            .owl-dots {
                position: absolute;
                bottom: -45px;
                button {
                    @include reset-input;
                    width: 32px;
                    height: 32px;
                    &.disabled {
                        opacity: 0.7;
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
            }
            .owl-nav {
                right: 0;
                .owl-prev {
                    background: $purple url("../img/icon-acc-prev-slider.svg") no-repeat center;
                }
                .owl-next {
                    margin-left: 8px;
                    background: #F5A623 url("../img/icon-acc-slider.svg") no-repeat center;
                }
            }
            .owl-dots {
                left: 0;
                bottom: -40px;
                span {
                    color: $gray-acc;
                    font-size: 14px;
                    font-weight: 600;
                }
                button {
                    width: auto;
                    background-color: transparent;
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
                .active {
                    span {
                        color: $black-acc;
                    }
                }
            }
        }
        .section-run-moments {
            margin-bottom: 75px;
            padding-left: 15px;
            padding-right: 15px;
            .owl-nav,
            .owl-dots {
                display: none;
            }
            @include tablet-up {
                padding-right: 40px;
                margin-bottom: 105px;
                padding-left: 0;
            }
            .row {
                @include space-col(16px);
                [class*="col"] {
                    margin-top: 16px;
                }
            }
            .h-510 {
                padding-top: 550/344*100%;
            }
            .h-285 {
                //padding-top: 285/344*100%;
                padding-top: 100%;
            }
            .h-247 {
                padding-top: 247/344*100%;
            }
            .h-209 {
                padding-top: 209/344*100%;
            }
            .img-drop {
                overflow: hidden;
                img {
                    overflow: hidden;
                }
            }
            .wrap-slider {
                .owl-nav {
                    @include tablet-up {
                        right: 40px;
                    }
                }
            }
        }
        .section-finished-board {
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 180px;
            margin-bottom: 55px;
            .link-event {
                display: none !important;
            }
            .section-tab-content {
                padding-top: 0;
                .list-ul-tab {
                    padding-top: 0;
                }
            }
            @include tablet-up {
                padding-right: 40px;
                padding-left: 0;
            }
            &.read-only {
                padding-top: 180px;
            }
            .wrap-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;
                .title-medium {
                    margin-bottom: 0;
                }
                .btn-view {
                    font-size: 14px;
                    font-style: italic;
                    font-weight: 400;
                    color: $black-acc;
                }
            }
            .wrap-slider {
                margin-bottom: 75px;
                @include tablet-up {
                    margin-bottom: 105px;
                }
            }
            .item-board {
                background-color: #FFF;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-bottom: 16px;
                padding: 20px 15px;
                flex-wrap: wrap;
                @include tablet-up {
                    flex-wrap: nowrap;
                }
                .name-board,
                .summer {
                    text-transform: uppercase;
                }
                .date,
                .time,
                .time-options {
                    font-size: 14px;
                }
                .summer,
                .date,
                .time,
                .time-options {
                    color: #9B9B9B;
                }
                .name-board {
                    width: 30%;
                }
                .summer {
                    width: 25%;
                }
                .date {
                    width: 20%;
                    text-align: center;
                    font-family: $font-Pop;
                }
                .time,
                .time-options {
                    width: 15%;
                    text-align: center;
                }
                .fb-actions {
                    width: 10%;
                    text-align: center;
                }
                @include tablet-down {
                    .name-board,
                    .date,
                    .summer,
                    .time,
                    .time-options {
                        flex-basis: 50%;
                        text-align: center;
                    }
                }
                @include small-mobile {
                    .name-board,
                    .date,
                    .summer,
                    .time,
                    .time-options {
                        flex-basis: 100%;
                        text-align: center;
                    }
                }
            }
            .row {
                @include space-col(16px);
                [class*="col"] {
                    margin-bottom: 16px;
                }
            }
            .item-inner {
                display: flex;
                padding: 15px;
                background: #FFFFFF;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                .wrap-img {
                    max-width: 89px;
                    width: 100%;
                    margin-right: 25px;
                    .img-drop {
                        padding-top: 100%;
                    }
                }
                .wrap-info {
                    flex-grow: 1;
                    .name-prod {
                        a {
                            text-decoration: none;
                            color: #333;
                        }
                    }
                }
                .wrap-price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    span {
                        color: $gray-acc;
                    }
                    .btn-get {
                        display: inline-block;
                        padding: 5px 20px;
                        background-color: $blueblack;
                        border-radius: 8px;
                        color: #FFF;
                        text-decoration: none;
                        &:hover {
                            background-color: rgba($blueblack, 0.5);
                        }
                        &.btn-cart-fix {
                            padding: 5px 10px;
                            line-height: 0;
                        }
                    }
                }
                .wrap-product-description {
                    display: none;
                }
            }
        }
        .section-purchase {
            padding-right: 15px;
            padding-left: 15px;
            padding-bottom: 5rem;
            @include tablet-up {
                padding-right: 0;
                padding-left: 0;
            }
            .wrap-table-purchase {
                @include tablet-up {
                    margin-right: 40px;
                }
                .list-title {
                    padding: 20px;
                    display: none;
                    @include tablet-up {
                        display: flex;
                    }
                    li {
                        font-size: 14px;
                        color: $gray-acc;
                        font-weight: 500;
                        &:nth-child(1) {
                            flex-basis: 15%;
                        }
                        &:nth-child(2) {
                            flex-basis: 10%;
                        }
                        &:nth-child(3) {
                            flex-basis: 40%;
                        }
                        &:nth-child(4),
                        &:nth-child(5) {
                            flex-basis: 15%;
                            text-align: center;
                        }
                        &:nth-child(6) {
                            flex-basis: 15%;
                            text-align: right;
                        }
                    }
                }
                .list-content {
                    .item-inner {
                        background: #FFFFFF;
                        margin-bottom: 16px;
                        ul {
                            display: flex;
                            align-items: center;
                            padding: 20px;
                            flex-wrap: wrap;
                            @include tablet-up {
                                flex-wrap: nowrap;
                            }
                            li {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                a {
                                    color: $black;
                                }
                                @include tablet-up {
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                                &:not(:last-child) {
                                    @include tablet-down {
                                        border-bottom: 1px solid $gray-acc;
                                    }
                                }
                                @include tablet-down {
                                    font-size: 14px;
                                    font-weight: 500;
                                    position: relative;
                                    padding-left: 45%;
                                    &:after {
                                        content: attr(data-label);
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        text-transform: capitalize;
                                        font-weight: 600;
                                    }
                                }
                                &:nth-child(1) {
                                    text-align: right;
                                    flex-basis: 100%;
                                    .wrap-img {
                                        max-width: 66px;
                                        margin-left: auto;
                                        @include tablet-up {
                                            margin-left: 0;
                                        }
                                        .img-drop {
                                            padding-top: 100%;
                                        }
                                    }
                                    @include tablet-up {
                                        flex-basis: 15%;
                                    }
                                }
                                &:nth-child(2) {
                                    text-align: right;
                                    flex-basis: 100%;
                                    @include tablet-up {
                                        flex-basis: 10%;
                                        text-align: left;
                                    }
                                }
                                &:nth-child(3) {
                                    text-transform: uppercase;
                                    text-align: right;
                                    flex-basis: 100%;
                                    @include tablet-up {
                                        flex-basis: 40%;
                                        text-align: left;
                                    }
                                }
                                &:nth-child(4),
                                &:nth-child(5) {
                                    flex-basis: 100%;
                                    text-align: right;
                                    @include tablet-up {
                                        text-align: center;
                                        flex-basis: 15%;
                                    }
                                }
                                &:nth-child(6) {
                                    flex-basis: 100%;
                                    text-align: right;
                                    @include tablet-up {
                                        flex-basis: 15%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modal-body {
            padding: 1rem 1rem;
            font-size: 12px;
            @include mobile-up {
                padding: 1rem 2rem;
                font-size: 16px;
            }
            .order-details {
                h3 {
                    font-size: 16px;
                    margin-top: 20px;
                    @include mobile-up {
                        font-size: 22px;
                        margin-bottom: 15px;
                    }
                }
                >h3 {
                    margin-top: 0;
                }
                tr {
                    &.col-product-wrap {
                        border-bottom: solid 1px $border-color; 
                        th {
                            color: $black-acc; 
                            font-family: 'Poppins';   
                            font-size: 10px;    
                            font-weight: bold;  
                            letter-spacing: 0.21px; 
                            line-height: 16px;
                            text-transform: uppercase;
                        }
                    }
                    &.col-product-total-wrap {
                        td {
                            text-align: right;
                            font-size: 22px;    
                            letter-spacing: 0.47px; 
                            line-height: 33px;
                            font-weight: bold;
                            padding-top: 10px;
                        }
                    }
                    
                }
                .col-product-body-wrap {
                    border-bottom: solid 1px $border-color;
                    font-size: 12px; 
                    font-weight: 500;
                    tr {
                        td {
                            padding-top: 15px;
                        }
                        &:last-child {
                            td {
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
                .col-product-footer-wrap {
                    border-bottom: solid 1px $border-color; 
                    font-size: 12px; 
                    font-weight: 500;
                    tr {
                        td {
                            padding-top: 12px;
                        }
                        &:last-child {
                            td {
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
                .order-billing-address,
                .order-shipping-address {
                    color: $black-acc; 
                    font-family: 'Poppins';
                    font-size: 12px;
                    font-weight: 500;
                    p {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .modal-dialog {
            @include mobile-up {
                width: 700px !important;
            }
        }
        .modal-header {
            position: absolute;
            right: 0;
            top: -5px;
            z-index: 99;
            justify-content: flex-end;
            border-bottom: 0 !important;
            a {
                color: $purple;
            }
        }
    }
    .account-products-section {
        .tab-pane {
            @include tablet-down {
                display: block !important;
                margin-bottom: 75px;
                &:before {
                    content: attr(data-label);
                    color: $orange;
                    margin-bottom: 25px;
                    text-transform: uppercase;
                    font-weight: 600;
                    text-decoration: none;
                    display: inline-block;
                    border-bottom: 2px solid;
                }
            }
        }
        .fade:not(.show) {
            @include tablet-down {
                opacity: 1 !important;
            }
        }
    }
}
.banner-footer {
    position: relative;
    margin-top: 72px;
    .img-drop {
        padding-top: 400/1440*100%;
    }
    .text-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        text-align: right;
        color: #FFFFFF;
        z-index: 1;
        transform: translate3d(-50%, -50%, 0);
        @include small-mobile-up {
            font-size: 36px;
        }
        @include tablet-up {
            font-size: 56px;
        }
    }
}
.text-require-field {
    text-transform: capitalize;
}
.order-paging {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin: 30px 0;
    li {
        display: inline-block;
        background-color: #fff;
        &.active {
            width: 40px;
            height: 40px;
            text-align: center;
            background-color: #000;
            border-radius: 50%;
            margin: 0 15px;
            line-height: 40px;
            a {
                color: #fff;
            }
        }
    }
    a {
        font-size: 20px;
        margin: 0 15px;
        color: $greysecond;
        &:hover {
            text-decoration: none;
        }
    }
}
.modal-order-review {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    table,
    .order-billing-address {
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
    }
    &.active {
        opacity: 1;
        pointer-events: fill;
        margin-top: 100px;
        &:before {
            content: '';
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            opacity: 0.8;
        }
    }
    .modal-close {
        color: #aaa;
        line-height: 50px;
        font-size: 80%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 25px;
        width: 70px;
        text-decoration: none;
        z-index: 9999;
        .fa-times {
            font-size: 30px;
            top: 25px;
            color: #fff;
        }
        &:hover {
            color: black;
            cursor: pointer;
        }
    }
}
.order-details {
    max-width: 650px;
    background: #ffffff;
    table {
        width: 100%;
        tr {
            th:nth-child(2),
            td:nth-child(2) {
                text-align: right;
            }
        }
    }
}
.order-status {
    text-transform: capitalize;
}
.wrap-table-order {
    @include small-mobile-up {
        padding: 40px;
        background-color: #f2f2f2;
    }
    .table-order-history {
        font-family: $font-Pop;
        font-weight: 500;
        width: 100%;
        @include small-mobile-up {
            background-color: #f2f2f2;
        }
        thead {
            @include small-mobile {
                display: none;
            }
            th {
                padding-bottom: 14px;
                font-weight: 700;
                color: $active-account;
                font-size: 14px;
                letter-spacing: 0.59px;
                line-height: 21px;
                text-transform: uppercase;
                &:nth-child(3) {
                    text-align: center;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
        tbody {
            font-size: 16px;
            a {
                color: #333;
                text-decoration: none;
                &:hover {
                    color: #333;
                }
            }
            td {
                padding: 10px;
                border-top: 1px solid #a7aaaa;
                background-color: #f2f2f2;
                @include small-mobile-up {
                    padding: 20px 0;
                }
                @include small-mobile {
                    display: block;
                    width: 100%;
                    text-align: right;
                    position: relative;
                    &:first-child {
                        border-top: 0;
                    }
                    &:before {
                        content: attr(data-attr);
                        position: absolute;
                        left: 15px;
                        font-weight: 700;
                        color: $active-account;
                        font-size: 14px;
                        letter-spacing: 0.59px;
                        line-height: 21px;
                        text-transform: uppercase;
                    }
                }
                &:nth-child(3) {
                    @include small-mobile-up {
                        text-align: center;
                    }
                }
                &:last-child {
                    text-align: right;
                    @include small-mobile {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
.order-reviews {
    color: $purple;
    font-weight: 500;
    &:hover {
        text-decoration: none;
    }
}
.wrtw-finshed-board-add {
    margin-bottom: 16px;
    .add-more-fb {
        margin-bottom: 15px;
        display: inline-block;
    }
    input {
        min-height: 46px;
        padding: 10px;
        border: 1px solid #a7aaaa;
        color: #494949;
        font-family: Poppins;
        width: 100%;
        margin-bottom: 15px
    }
    input[type="date"]::before { 
        content: attr(placeholder); 
        width: 100%;
        color: $black-acc;
        opacity: 0.8;
    }
    input[type="date"]:focus::before,
    input[type="date"]:valid::before { 
        display: none 
    }
    .form-group {
        max-width: 100%;
    }
    .form-half {
        flex-basis: 50%;
        max-width: 50%;
    }
    .wrap-time-run {
        display: flex;
    }
    button[type="submit"] {
        background-color: $purple;
        border-color: $purple;
        padding: 12px 30px;
        min-width: 203px;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.6;
        }
    }
    #wrtw-finish-board {
        display: none;
        &.active {
            display: block;
        }
    }
    .wrtw-loading {
        margin-left: 7px;
    }
    .repsonse-info {
        &.woocommerce-message {
            margin-bottom: 15px;
        }
    }
}
.item-board-wrap {
    .info-board {
        &.disable {
            display: none;
        }
    }
    input {
        display: none;
        &.enable {
            display: block;
        }
    }
    .btn-fb-edit {
        &.disable {
            display: none;
        }
    }
    .fa-check-circle {
        font-size: 30px;
        color: $purple;
    }
    .btn-fb-ok {
        display: none;
        position: relative;
        top: -8px;
        &.enable {
            display: block;
        }
    }
}
.moments-item {
    position: relative;
    overflow: hidden;
    &:hover {
        .moments-action {
            opacity: 0.6;
        }
    }
    .moments-action {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: opacity .25s ease;
        -moz-transition: opacity .25s ease;
        -o-transition: opacity .25s ease;
        -ms-transition: opacity .25s ease;
        transition: opacity .25s ease;
    }
    a {
        width: 100%;
        display: inline-block;
        color: #fff;
        margin-bottom: 15px;
        text-align: center;
    }
    .action-items {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.disable {
            display: none;
        }
    }
    .moment-replace,
    .moment-delete {
        color: #fff !important;
    }
    &.upload-user-avatar {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
        img {
            width: 150px;
            height: 150px;
        }
    }

}
.moments-add-item-warp {
    position: relative;
    padding-top: 100%;
    &.none-relative {
        position: inherit;
        padding-top: 0;
        .moments-item {
            cursor: pointer;
        }
    }
    .moments-add-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed $purple;
    width: 100%;    
    background-color: transparent;
    transition: width 0.3s;
    &.upload-avatar-user {
        width: 150px;
        min-height: 150px;
        font-size: 13px;
        text-align: center;
    }
    .fa-plus-circle {
        font-size: 20px;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
    &.disable {
        &:hover {
            background: transparent;
            cursor: default;
        }
    }
    .add-item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $purple;
        &.disable {
            display: none;
        }
    }
    .message-item {
        position: absolute;
        bottom: 20px;
        left: 0;
        color: $purple;
        text-align: center;
        width: 100%;
        padding: 0 10px;
        font-size: 14px;
        top: unset;
        transform: none;
    }
    .moment-replace {
        opacity: 0;
        &.upload {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    .moment-loading {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: red;
        }
    }
}
}

.moments-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: center;
    display: flex;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
#wrtw-form-upload-form {
    .wrtw-form-upload {
        display: none !important;
    }
}
.repsonse-moments {
    .response-alert {
        color: $pink;
        background-color: #fff;
        padding: 10px;
    }
}
.wrtw-loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, .3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    &.bg {
        border-top-color: $purple;
    }
}
#box-search-user {
    position: relative;
    @include tablet-up {
        padding: 25px 38px;
    }
    .search-user-loading {
        &.active {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .wrtw-loading {
        border-top-color: $purple;
    }
}
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
#box-search-friend {
    @include reset-input;
    @include reset-autofill(#000);
    height: 42px;
    padding: 6px 40px 6px 25px;
    font-size: 14px;
    color: #494949;
    width: 100%;
    border-radius: 4px;
    background: #eeeeee url('../img/icon-acc-magnifier.svg') center right 15px/ 25px 25px no-repeat;
    @include placeholder {
        color: rgba(73, 73, 73, 0.5);
        transition: all .3s ease;
    }
    &:focus {
        @include placeholder {
            opacity: 0;
        }
    }
}
#form-address-edit {
    position: relative;
}
.wrap-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.wrtw-form-login {
    max-width: 350px;
    margin: 0 auto;
}
.form-row-upload-user-avatar {
    margin-bottom: 5px !important;
}
.user-avatar-area {
    position: relative;
    width: 150px;
    height: 150px;
    .moments-add-item {
        border: 2px dashed #49286f;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .add-item {
            text-align: center;
            i {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}
.user-avatar-area-message {
    position: absolute;
    bottom: 0;
    left: 170px;
}
.ava {
    &.fix-size {
        img {
            width: 82px !important;
            height: 82px !important;
        }
    }
}
.message-user-upload-avatar {
    color: $red;
    font-size: 14px;
}
.tooltip {
    z-index: 101;
    &.show {
        opacity: 1;
    }
    &.bs-tooltip-right {
        .arrow::before {
            border-right-color: $bg-gray-2;
        }
    }
    &.bs-tooltip-left {
        .arrow::before {
            border-left-color: $bg-gray-2;
        }
    }
    &.bs-tooltip-top {
        .arrow::before {
            border-top-color: $bg-gray-2;
        }
    }
    &.bs-tooltip-bottom {
        .arrow::before {
            border-bottom-color: $bg-gray-2;
        }
    }
   .tooltip-inner {
        max-width: 400px;
        width: 100%;
        padding: 15px;
        background: $white;
        color: $black;
        border: solid 1px $bg-gray-2;
        .description-wrap {
            display: flex;
            .wrap-img {
                max-width: 89px;
                width: 100%;
                margin-right: 15px;
                .img-drop {
                    padding-top: 100%;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }  
            .description-content {
                flex-grow: 1;
                text-align: left;
                a {
                    color: $black;
                    font-weight: bold;
                }
                p {
                    margin-bottom: 5px;
                    &.product-title {
                        font-weight: bold;
                        font-size: 1rem;
                        margin-bottom: 10px;
                    }
                }
            }          
        }
    } 
}
#form-address-edit {
    .field-shipping-warp {
        &.field-shipping-edit-warp {
            border: solid 1px $grey;
            padding: 25px 20px;            
        }        
        .msg-error-form {
            margin-bottom: 0;
        }
        .form-row {
            &.no-edit {
                label {
                    margin-bottom: 0;
                    font: 600 12px/18px Raleway;
                    color: #A7AAAA;
                    .required {
                        display: none;
                    }
                }
                input {
                    border: 0 !important;
                    background: transparent;
                    min-height: auto;
                    height: auto;
                    padding: 0;
                    &[readonly] {
                        cursor: auto;
                    }
                    color: $black-acc;
                    font: 500 14px/24px 'Poppins';
                }
                select+.select2-container {
                    background: transparent;
                    height: auto;
                    pointer-events: none;
                    .select2-selection--single {
                        border: 0;
                        background: transparent;
                        color: $black-acc;
                        
                        .select2-selection__rendered {
                            padding: 0;
                            line-height: normal;
                            font: 500 14px/24px 'Poppins';
                        }
                        .select2-selection__arrow {
                            display: none;
                        }
                    }
                }
                .msg-error {
                    display: none;
                }
                .woocommerce-input-wrapper {
                    display: none;
                }
                .optional {
                    display: none;
                }
                .field-billing-view {
                    display: block;
                }
            }
        }
        .icon-edit-billing-field-wrap {
            text-align: right;
            a {
                .icon-edit-billing-field {
                    width: 14px;
                    height: 14px;
                    background-image: url('../../upload/icon-edit.png');
                    display: inline-block;
                    background-size: 100%;
                } 
                span {
                    font-family: Poppins;
                    font-size: 16px;
                    color: #4A90E2;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .button-wap {
            margin-top: 17px;
            .btn-save-settings-edit {
                text-align: center;
                &:hover {
                    color: $white;
                }
            }
        }
        .field-billing-view {
            color: #494949;
            font: 500 14px/24px Poppins;
            display: none;
        }
    }
}
