footer.footer-page {
    border-top: 3px solid #49286F;
    margin-top: 70px;
    padding-top: 30px;
    padding-bottom: 30px;
    @include mobile-up {
        margin-top: 110px;
        padding-bottom: 70px;
    }
    .title {
        font-weight: 700;
        letter-spacing: 0.4px;
        line-height: 18px;
        text-transform: uppercase;
        display: inline-block;
        padding: 5px 20px;
        margin-bottom: 20px;
        background: #ffffff;
        position: absolute;
        top: -45px;
        left: -5px;
    }
    .phone,
    .address {
        font-family: $font-Pop;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        margin-bottom: 15px;
        text-transform: uppercase;
        max-width: 203px;
        color: #494949;
        a {
            color: #494949;
        }
        @include mobile-up {
            margin-bottom: 30px;
        }
    }
    .social {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-transform: uppercase;
        &>span {
            margin-bottom: 15px;
            display: block;
        }
    }
    .wrap-form-subscribe {
        display: flex;
        align-items: flex-end;
        text-transform: uppercase;
    }
}
.form-subscribe {
    padding: 24px 20px;
    width: 100%;
    color: #fff;
    margin-top: 20px;
    @include desktop-up {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text {
        min-width: 45%;
        margin-right: 10px;
        @include desktop-down {
            width: 100%;
            margin-right: 0;
        }
    }
    .ask {
        font-family: $font-br;
        font-weight: 600;
        font-size: 16px;
        color: #999990;
        margin-bottom: 2px;
    }
    .awe {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 18px;
        margin-bottom: 15px;
        @include desktop-up {
            margin-bottom: 0;
        }
    }
    form {
        position: relative;
        display: flex;
        flex: auto;
    }
    input[type="text"],
    input[type="email"] {
        @include reset-input;
        @include reset-autofill(#fff);
        @include placeholder {
            color: rgba(#fff, .5);
        }
        background: transparent;
        color: #fff;
        text-transform: uppercase;
        display: block;
        font-size: 12px;
        border-bottom: 1px $grey solid;
        margin-right: 30px;
        padding-bottom: 10px;
        width: 100%;
        letter-spacing: .3px;
    }
    input[type="submit"] {
        @include reset-input;
        background: transparent;
        text-transform: uppercase;
        font-family: $font-br;
        font-weight: 600;
        font-size: 16px;
        color: #999990;
        text-align: center;
        cursor: pointer;
    }
    .validation_error {
        display: none;
    }
    .gform_fields {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    }
    .gfield_label {
        display: none;
    }
    .gform_body {
        margin-right: 10px;
        @include mobile-up {
            padding-left: 10px;
            padding-right: 10px;
        }
        width: 100%;
    }
    .validation_message {
        font-size: 11px;
        position: absolute;
        top: calc(100% + 5px);
    }
    .gform_wrapper {
        width: 100%;
    }
    .gform_validation_container {
        display: none;
    }
}
ul.list-social-icon {
    li {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
    a {
        width: 36px;
        height: 36px;
        background: $grey;
        color: #fff;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        &:hover {
            background: $gradient;
        }
    }
}
// BAR FOOTER
.bar-footer {
    padding: 27px 0;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    overflow: hidden;
    a {
        color: #fff;
        @include tablet-down {
            display: inline-block;
        }
    }
    ul.nav {
        font-weight: 600;
        font-size: 12px;
        @include tablet-down {
            margin-bottom: 5px;
        }
        li {
            &:not(:last-child) {
                margin-right: 20px;
                @include max-desktop {
                    margin-right: 50px;
                }
            }
            @include tablet-down {
                margin-bottom: 10px;
            }
            @include small-mobile {
                margin-right: 0 !important;
            }
        }
        a {
            @include desktop-down {
                display: inline-block;
                padding-right: 10px;
            }
        }
    }
    .copyright {
        @include small-mobile {
            text-align: center;
        }
    }
    .ven {
        display: block;
        text-transform: none;
        font-weight: 400;
        text-align: right;
        @include small-mobile {
            margin-top: 5px;
            text-align: center;
        }
    }
}