 // THE-ABOUT-INTRO
 section.the-about-intro {
     .container {
         color: #fff;
         padding-bottom: 40px;
         @include tablet-up {
             padding-bottom: 200px;
         }
     }
     .title-intro {
         font-size: 24px;
         line-height: 32px;
         @include tablet-down {
             margin-bottom: 20px;
         }
     }
     .text-intro {
         line-height: 24px;
     }
 } // THE-ABOUT-PERSON
 section.the-about-person {
     .container {
         padding-bottom: 0px;
         @include tablet-up {
             padding-bottom: 180px;
         }
     }
     .text-person {
         line-height: 24px;
     }
     .title-page {
         @include mobile-down {
             font-size: 26px;
         }
     }
     .des-page {
         @include mobile-down {
             font-size: 16px;
         }
     }
     .nav {
         font-weight: 700;
         font-size: 20px;
         border-top: 2px solid $purple;
         padding-top: 20px;
         padding-bottom: 20px;
         @include tablet-up {
             font-size: 24px;
             padding-top: 50px;
             padding-bottom: 50px;
         }
         a {
             color: $gray;
             text-decoration: none;
             transition: color 0.4s ease;
             position: relative;
             padding: 8px 0;
             transform: skewX(-17.5deg);
             span {
                 transform: skewX(17.5deg);
                 display: block;
             }
             &.active,
             &:hover {
                 color: $purple;
             }
             &.active:before,
             &.active::after {
                 @include tablet-up {
                     opacity: 1;
                     transform: scale(1);
                 }
             }
             &:not(:last-child) {
                 margin-right: 20px;
                 @include tablet-up {
                     margin-right: 50px;
                 }
             }
             &:before,
             &:after {
                 content: "";
                 position: absolute;
                 top: -10px;
                 left: -20px;
                 bottom: -10px;
                 right: -20px;
                 border: 3px solid transparent;
                 transform: scale(0);
                 opacity: 0;
                 transition: all 0.4s ease;
             }
             &:before {
                 border-left: 3px solid $purple;
                 border-bottom: 3px solid $purple;
                 transform-origin: 0 100%;
             }
             &:after {
                 border-top: 3px solid $purple;
                 border-right: 3px solid $purple;
                 transform-origin: 100% 0;
             }
         }
     }
     .img-person {
         @include tablet-up {
             margin-top: -240px;
             max-width: 540px;
         }
         @include tablet-down {
             @include max-width-center(350px);
             margin-bottom: 20px;
         }
         .img-drop {
             padding-top: 710/530*100%;
         }
         .img-drop:after {
             content: "";
             position: absolute;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             background: rgba(#49286F, 0.3);
         }
         .img-drop::before {
             @include tablet-up {
                 content: "";
                 position: absolute;
                 top: 20%;
                 right: 40%;
                 width: 150%;
                 height: 100%;
                 background: $bg-gray;
                 transform: skewX(-17.5deg);
             }
         }
     }
     &.img-right {
         .img-person {
             margin-left: auto;
             @include tablet-up {
                 margin-top: -125px;
             }
             .img-drop::before {
                 top: 25%;
                 right: -90%;
                 transform: skewX(17.5deg);
             }
         }
         @include tablet-up {
             .img {
                 order: 2;
             }
             .text {
                 order: 1;
             }
         }
     }
 }

 section.the-home-lastnews {
    .wrap-more-button {
        margin-top: 50px;
    }
 }