.thankyou-img {
    position: relative;
    padding-bottom: 40px;
    @include mobile-down {
        padding-bottom: 30px;
    }
    h3 {
        font: 700 36px/ normal $font-br;
        letter-spacing: 0.9px;
        color: #fff;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, calc(-50% - 15px), 0);
        z-index: 2;
        @include small-mobile {
            font-size: 20px;
        }
    }
    .img-drop {
        padding-top: 100%/(873/300);
        &:before {
            background: rgba(73, 40, 111, 0.7);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            z-index: 1;
        }
    }
}

.privacy-message {
    font-size: 13px;
    line-height: 1.2rem;
    font-weight: 500;
}

.woocommerce .woocommerce-checkout-review-order table.woocommerce-checkout-review-order-table tbody .cart_item>td.product-name span.qty {
    margin-left: 50% !important;
}

.ml-auto {
    margin-left: auto;
}

.checkout-receipt-event {
    display: flex;
    align-items: center;
    a {
        margin-left: 30px;
        vertical-align: middle;
        &:hover {
            opacity: .7;
        }
    }
}

.top-checkout {
    padding-bottom: 25px;
    align-items: center;
    a.help-btn {
        color: #0066C0;
        font-size: 12px;
    }
}

.payment-choice {
    padding-bottom: 30px;
}

.title-checkout {
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-br;
    align-items: center;
    padding-right: 15px;
}

.receipt-item {
    padding-bottom: 18px;
}

.receipt-item-row {
    padding-bottom: 8px;
    .receipt-item-price {
        margin-left: auto;
    }
}

.receipt-item-name {
    color: #A7AAAA;
    font-family: $font-br;
    font-weight: 700;
    padding-right: 15px;
}

.receipt-item-price {
    color: #A7AAAA;
    font-family: $font-br;
    font-weight: 700;
}

.receipt-total-price {
    .receipt-price {
        margin-left: auto;
    }
}

.receipt-price {
    font-size: 24px;
    letter-spacing: 0.3px;
}

.receipt-summary {
    padding-bottom: 20px;
}

.radio-payment,
.wc_payment_method {
    padding-bottom: 15px;
    &>input {
        display: none;
        &:checked+label {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .payment_box {
        margin-top: 15px;
    }
    &>label {
        margin-bottom: 0;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            background: transparent url('../img/oval-border.svg') left center/ 13px 13px no-repeat;
            left: 0px;
            top: 4px;
            width: 13px;
            height: 13px;
            transform: translate3d(0, 0, 0);
        }
        &:after {
            content: '';
            position: absolute;
            left: 0px;
            top: 4px;
            width: 13px;
            height: 13px;
            background: transparent url('../img/oval-selected.svg') left center/ 13px 13px no-repeat;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
            transform: translate3d(0, 0, 0);
            z-index: 2;
        }
        img {
            max-height: 16px;
            width: auto;
            margin-right: 16px;
        }
    }
}

.payment-info {
    margin: 0 -10px;
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

.payment-info-row {
    padding: 0 10px 15px;
    width: 100%;
    float: left;
    &.col-50 {
        width: 50%;
    }
    &.cvc {
        padding-bottom: 30px;
        input {
            width: calc(50% - 10px);
        }
    }
    &.card-number {
        input {
            background: transparent url('../../upload/mastercard.svg') center right 15px/ 27px 16px no-repeat;
            padding-right: 50px;
        }
    }
    label {
        display: block;
        font-size: 12px;
        color: $grey;
        text-transform: uppercase;
    }
    input {
        @include reset-input;
        width: 100%;
        padding: 10px 16px;
        outline: none;
        border: 1px solid $grey;
        font: 600 16px/ normal $font-Rr;
        color: #333;
        background: transparent;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }
    }
    select {
        width: 100%;
        appearance: none;
        outline: none;
        border-radius: 0;
        width: 100%;
        padding: 10px 16px;
        border: 1px solid $grey;
        font: 600 16px/ normal $font-Rr;
        color: #333;
        cursor: pointer;
        background: url('../img/arrow-down.svg') right 15px center/ 15px 7px no-repeat, linear-gradient(#EEEEEE, #D8D8D8);
    }
}

.cvc-guide {
    display: inline-block;
    cursor: help;
    padding-left: 20px;
    color: $grey;
}

.btn-payment-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font: 600 12px/ normal $font-Rr;
    background: #7DB445;
    border: 0;
    border-radius: 0;
    outline: none;
    padding: 12px 15px;
    width: 100%;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s ease;
    letter-spacing: 0.3px;
    i {
        margin-right: 8px;
    }
    &:hover {
        background: $purple;
    }
}

.guarantee-line {
    color: $grey;
    padding: 0 15px;
    i {
        margin-right: 8px;
        vertical-align: top;
    }
}

//THANK YOU PAGE
section.thank-you {
    .row {
        @include mobile-up {
            @include space-col(100px);
        }
    }
}

.thank-you-box {
    padding: 93px 0;
    @include mobile-down {
        text-align: center;
    }
    img {
        max-width: 100%;
    }
    p {
        line-height: 24px;
        margin-bottom: 24px;
    }
    a {
        color: $grey;
    }
    .thank-you-img {
        @include mobile-down {
            max-width: 120px;
            margin-bottom: 20px;
        }
    }
}

.thank-you-headline {
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-br;
    margin-bottom: 20px;
}

//************************//
//*****NEW CHECKOUT******//
//**********************//
section.checkout {
    padding-top: 50px;
    @include tablet-down {
        padding-top: 30px;
    }
}

.select2-dropdown {
    .select2-results__option {
        font-size: 12px;
    }
}

.woocommerce {
    .checkout {
        .woocommerce-error {
            background: none;
            padding-left: 20px;
            &>li {
                color: $pink;
            }
        }
        .woocommerce-checkout {
            h3 {
                font: 700 16px/1em $font-br;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .form-row {
                margin-bottom: 15px;
                label {
                    font: 600 12px/18px $font-Rr;
                    letter-spacing: .3px;
                    margin-bottom: 8px;
                    color: #A7AAAA;
                    text-transform: uppercase;
                    abbr {
                        text-decoration: none;
                    }
                }
                .woocommerce-input-wrapper {
                    input {
                        @include reset-input;
                        height: 46px;
                        width: 100%;
                        padding: 5px 16px;
                        font: 600 16px/1em $font-Rr;
                        letter-spacing: .2px;
                        color: $black;
                        border: 1px solid #A7AAAA;
                        @include placeholder {
                            font: 600 12px/1em $font-Rr;
                            letter-spacing: .15px;
                            color: #A7AAAA;
                        }
                    }
                    select {
                        &+.select2-container {
                            height: 46px;
                            &.select2-container--open {
                                .select2-selection--single {
                                    .select2-selection__arrow b {
                                        transform: translate3d(-50%, -50%, 0) rotate(0);
                                    }
                                }
                            }
                            .select2-selection--single {
                                outline: none;
                                height: 100%;
                                border-radius: 0;
                                border: 1px solid #A7AAAA;
                                .select2-selection__rendered {
                                    font: 600 16px/1em $font-Rr;
                                    color: #333;
                                    padding: 0 35px 0 16px;
                                    line-height: 46px;
                                }
                            }
                            .select2-selection__arrow {
                                height: 100%;
                                top: 0;
                                width: 35px;
                                b {
                                    transform: translate3d(-50%, -50%, 0) rotate(180deg);
                                    margin-left: -5px;
                                    margin-top: 0;
                                    border: 0;
                                    width: 15px;
                                    height: 8px;
                                    background: transparent url(../img/arrow-up-black.svg) no-repeat center/auto 100%;
                                }
                            }
                        }
                    }
                }
                &#order_comments_field {
                    .woocommerce-input-wrapper {
                        textarea {
                            @include reset-input;
                            height: 150px;
                            width: 100%;
                            padding: 15px 16px;
                            font: 600 16px/1em $font-Rr;
                            letter-spacing: .2px;
                            color: $black;
                            border: 1px solid #A7AAAA;
                            resize: none;
                            @include placeholder {
                                font: 600 12px/1em $font-Rr;
                                letter-spacing: .15px;
                                color: #A7AAAA;
                            }
                        }
                    }
                }
            }
            .btn-light {
                margin-top: 10px;
            }
        }
    }
    .woocommerce-checkout-review-order {
        table.woocommerce-checkout-review-order-table {
            width: 100%;
            border: 0;
            border-radius: 0;
            border-collapse: collapse;
            margin-bottom: 30px;
            tbody {
                .cart_item {
                    &:nth-child(2n) {
                        background: rgba(#f2f2f2, .5);
                    }
                    &>td {
                        padding: 12px 10px;
                        border-top: 0;
                        vertical-align: top;
                        &.product-thumb {
                            min-width: 92px;
                            .img-drop {
                                border: 1px solid #f2f2f2;
                                img {
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                        &.product-name {
                            h6 {
                                font: 700 16px/20px $font-br;
                                color: $black;
                                letter-spacing: .2px;
                                margin-bottom: 14px;
                            }
                            p {
                                display: flex;
                                margin-bottom: 0;
                            }
                            span.size {
                                margin-right: 15px;
                                font-size: 14px;
                            }
                            span.qty {
                                margin-left: auto;
                                font-size: 14px;
                            }
                        }
                        &.product-total {
                            text-align: right;
                            font-weight: 700;
                            font: 700 16px/ normal $font-br;
                        }
                    }
                }
            }
            tfoot {
                th,
                td {
                    border-top: 0;
                    padding: 12px 10px;
                }
                th {
                    font: 700 16px/20px $font-br;
                }
                td {
                    &:last-child {
                        text-align: right;
                        font: 700 16px/20px $font-br;
                    }
                }
            }
        }
    }
    .woocommerce-checkout-payment#payment {
        border-radius: 0;
        background: none;
        ul.payment_methods {
            padding: 0;
            border-bottom: 0;
            list-style: none;
        }
        div.form-row {
            padding: 0;
        }
    }
    #place_order {
        @include reset-input;
        min-height: 46px;
        max-width: 315px;
        width: 100%;
        display: inline-flex;
        font: 600 12px/18px $font-Rr;
        color: #f2f2f2;
        text-transform: uppercase;
        justify-content: center;
        text-align: center;
        align-items: center;
        background: #7DB445;
        transition: all .3s ease;
        border-radius: 0;
        border: 0;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            opacity: .8;
            color: #f2f2f2;
        }
    }
}

.customer_details {
    @include mobile-up {
        max-width: 540px;
    }
    @include mobile-down {
        margin-bottom: 35px;
    }
}

.billing-container {
    padding-bottom: 55px;
}

#ship-to-different-address {
    text-transform: none;
    &>label {
        position: relative;
        padding-left: 30px;
        font: 600 12px/1em $font-Rr;
        color: $black;
        margin-bottom: 0;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            width: 12px;
            height: 12px;
            background: transparent url(../../src/img/checkbox.svg) center left/contain no-repeat;
            transition: all .3s ease;
        }
        &.checkbox-active {
            &:before {
                background: transparent url(../../src/img/checkbox-active.svg) center left/contain no-repeat;
            }
        }
    }
    input {
        display: none;
    }
}

//STICKY PAYMENT
.left-col-payment {
    float: left;
    width: calc(50% - 30px);
    margin-right: 30px;
    @include mobile-down {
        width: 100%;
        float: none;
    }
}

.main-content {
    display: flex;
    @include mobile-down {
        flex-direction: column;
    }
}

.sidebar {
    will-change: min-height;
    float: left;
    width: 50%;
    @include mobile-down {
        width: 100%;
        float: none;
    }
    .title-blog {
        margin-bottom: 30px;
    }
}

.sidebar__inner {
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.woocommerce-form-coupon-toggle {
    margin-bottom: 25px;
    .woocommerce-info {
        background: transparent;
    }
}

.checkout_coupon.woocommerce-form-coupon {
    .form-row {
        input {
            @include reset-input;
            height: 46px;
            width: 100%;
            padding: 5px 16px;
            font: 600 16px/1em Raleway;
            letter-spacing: .2px;
            color: #333;
            border: 1px solid #a7aaaa;
            max-width: 315px;
            @include placeholder {
                font: 600 12px/1em $font-Rr;
                letter-spacing: .15px;
                color: #A7AAAA;
            }
        }
        button {
            @include reset-input;
            height: 46px;
            max-width: 315px;
            width: 100%;
            display: inline-flex;
            font: 600 12px/18px Raleway;
            color: #f2f2f2;
            text-transform: uppercase;
            justify-content: center;
            text-align: center;
            align-items: center;
            background: $gradient;
            transition: all .3s ease;
        }
    }
}

.wc-credit-card-form {
    .stripe-card-element {
        font: 600 12px/18px Raleway;
        letter-spacing: .3px;
        margin-bottom: 8px;
        color: #a7aaaa;
        text-transform: uppercase;
    }
    .wc-stripe-elements-field {
        @include reset-input;
        margin-top: 0;
        height: 46px;
        width: 100%;
        padding: 14px 16px;
        font: 600 16px/1em $font-Rr;
        letter-spacing: .2px;
        color: $black;
        border: 1px solid #A7AAAA;
        @include placeholder {
            font: 600 12px/1em $font-Rr;
            letter-spacing: .15px;
            color: #A7AAAA;
        }
        &.invalid {
            border-color: red;
        }
    }
}

.sticky-sidebar {
    h3 {
        color: $greysecond;
    }
}
.woocommerce-checkout {
    .woocommerce {
        .woocommerce-checkout-review-order {
            .shipping-desc-checkoutpage {
                .desc-colspan {
                    font-family: $font-Pop!important;
                    font-size: 14px !important;
                    line-height: 24px;
                    text-align: center !important;
                    color: $greysecond !important;
                    font-weight: normal !important;
                }
            }
            .privacy-message {
                a {
                    color: $back-2 !important;
                }
            }
            .product-name {
                .size, .qty {
                    color: $headingtext;
                }
            }
            a.help-btn {
                color: $back-2 !important;
            }
            #place_order {
                background: $gradient;
            }
        }
    }
}

.payment_method_stripe {
    a {
        color: $back-2;
    }
}
.checkout {
    .woocommerce-info {
        a.showcoupon {
            color: $back-2;
        }
    }
}
.product-quantity {
    text-align: center;
    span {
      color: $headingtext;
    }
}
.container-check-out-cart-error {
    min-height: 37vh;  
    .row {
        align-items: center;
        min-height: inherit;
        .col-md-8 {
            text-align: center;
            ul {
                padding: 0;
                list-style: none;
                li {
                    list-style: none;
                }
            }
            .btn-edit {
                margin-top: 30px;
            }
        }
        .woocommerce-error {
            display: block !important;
            background: transparent;
        }
    }
}