/* VAR */
$pink: #F70063;
$gray: #DADADA;
$green: #7bb7bb;
$green-heavy: #357f83;
$text-gray: #F2F2F2;
$black-acc: #494949;
$gray-acc: #999999;
$gray-text: #C6C6C6;
$gray-bg: #D8D8D8;
$gray-select: #979797;
$orange : #F5A623;
$title-home: #21285D;
/* FONT */
$font-Rr: 'Raleway';
$font-BranB: "Brandon Grotesque";
$font-br: "Brandon Grotesque";
$font-Pop: 'Poppins';
$font-Go: 'Gotham';
$shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
/* COLOR */
// Primary
$purple: #49286F;
// Grey: */
$grey: #A7AAAA;
$greysecond: #999990;
$gray-bg-product: #F4F4F4;
$white: #fff;
$black: #000;
$active-account: #2C2862;
// Black: */
$black: #333333;
$color-border-select: #7DB445;

// Primary Gradient: */
$gradient: linear-gradient(-135deg, #21285D 0%, #49286F 100%);
// Rectangle: */
$gradientver: linear-gradient(135deg, #21285D 0%, #49286F 100%);
$gradientver2: linear-gradient( 135deg, #353869 0%, #49286F 100% );
// Secondary: */
$blueblack: #4C3274;
$headingtext: #9B9B9B;
$text-workout: #929292;
$bg-gray: #f3f3f3;
$bg-gray-2: #ccc;
$back-2: #333;
/* 
*
*GRID
*
*/
$grid_container: 1320px;
$grid_container_max: 2000px;

$border-color: #BCBCBC;


$red: #dc1a1a;