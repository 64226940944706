.google-map-contact {
    height: 400px;
    position: relative;
    @include tablet-up {
        height: 650px;
    }
    #google-map-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .gm-style-iw+div img {
        display: none;
    }
    div[style] {
        border-radius: 0 !important;
    }
    .gmnoprint,
    .gm-style-cc,
    .gm-style-cc+button,
    a[rel="noopener"] {
        display: none !important;
    }
} 
.wrtw-infomap {
    font-family: $font-Rr;
    font-weight: 700;
    font-size: 16px;
    color: $black;
    letter-spacing: 0.2px;
    line-height: 21px;
    padding: 10px 0px 10px 15px;
    max-width: 240px;
}
// THE-HOME-INS
section.the-contact-page {
    .wrap-contact-form {
        @include max-width-center(580px);
        margin-bottom: 20px;
        .validation_error,
        .gfield_label {
            display: none;
        }
        ul {
            padding-left: 0;
            list-style: none;
        }
        input:not([type="submit"]),
        textarea {
            @include reset-autofill($black);
        }
        .gform_ajax_spinner {
            position: absolute;
            right: 45px;
            width: 40px;
            height: 40px;
            top: 0;
            @include mobile-up {
                top: 10px;
            }
        }
    }
    .wrap-contact-info {
        margin-top: 40px;
        @include tablet-up {
            margin-top: 70px;
        }
    }
}
.item-contact-info {
    position: relative;
    margin-bottom: 20px;
    padding-left: 60px;
    transition: margin 0.4s ease;
    @include desktop-down {
        padding-left: 50px
    }
    @include tablet-up {
        margin-bottom: 50px;
    }
    .ico {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
        width: 36px;
        height: 36px;
        background: $gradient;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .type {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 10px;
        @include tablet-down {
            font-size: 20px;
        }
    }
}
.the-contact-page {
    .validation_message {
        color: red;
    }
}
.the-contact-page {
    .item-contact-info {
        .type {
            color: $blueblack;
            display: inline-flex;
            margin-top: -20px;
        }
    }
}