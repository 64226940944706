//***************//
//EVENTS FEATURE//
//*************//
.events-feature {
    padding-bottom: 100px;
    @include tablet-down {
        padding-bottom: 50px;
    }
    .row {
        @include space-col(60px);
    }
    .col-lg-4 {
        @include tablet-down {
            padding-bottom: 25px;
        }
    }
    .img-events-ft {
        padding-top: 100%/(760/440);
        margin-bottom: 30px;
        @include small-mobile {
            margin-bottom: 20px;
        }
        img {
            transition: all .3s ease;
        }
        &:hover {
            img {
                opacity: .7;
            }
        }
    }
    .btn-edit {
        border: 0;
        align-self: flex-start;
        background: #fff;
        font-weight: 700;
        margin-top: 12px;
    }
    .title-page {
        line-height: 42px;
        margin-bottom: 20px;
        a {
            color: #fff;
            transition: all .3s ease;
            &:hover {
                text-decoration: none;
                opacity: .7;
            }
        }
        @include tablet-up {
            line-height: 48px;
            margin-bottom: 30px;
        }
    }
    .wrap-title {
        @include tablet-up {
            max-width: 315px;
        }
    }
    .wrap-des {
        max-width: 560px;
    }
    .event-cate {
        a {
            color: $white;
            margin-bottom: 5px;
            display: inline-block;
        }
    }
}
.text-events {
    color: #fff;
    line-height: 24px;
    padding-top: 12px;
    position: relative;
    margin-right: 25px;
    font-weight: 500;
    @include small-mobile {
        margin-bottom: 15px;
        margin-right: 0;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(-50%, 0, 0);
        height: 2px;
        width: 24px;
        background: #fff;
    }
    &.black {
        color: $black;
        &:before {
            background: $purple;
        }
    }
}
//***************//
//EVENTS ARCHIVE//
//*************//
.events-archive {
    padding-top: 100px;
    @include tablet-down {
        padding-top: 50px;
    }
    .row {
        @include space-col(20px);
    }
}
.date {
    color: #fff;
    font: 600 14px/ normal $font-Rr;
    &.purple {
        color: $purple;
    }
}
.event-cate {
    text-transform: uppercase;
}
//***************//
//EVENTS BLOCK//
//*************//
.events-block {
    padding-bottom: 60px;
    @include tablet-down {
        padding-bottom: 30px;
    }
    .img-events-block {
        display: block;
        margin-bottom: 2rem;
        width: 100%;
        @include tablet-down {
            margin-bottom: 1rem;
        }
        &:hover {
            .overlay-events {
                opacity: 1;
            }
            .img-drop {
                &:before,
                &:after {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
        .img-drop {
            padding-top: 100%/(425/585);
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 20px;
                left: 20px;
                bottom: 20px;
                right: 20px;
                border: 3px solid transparent;
                transform: scale(0);
                opacity: 0;
                transition: all 0.4s ease;
                z-index: 2;
            }
            &:before {
                border-left: 3px solid #fff;
                border-bottom: 3px solid #fff;
                transform-origin: 0 100%;
            }
            &:after {
                border-top: 3px solid #fff;
                border-right: 3px solid #fff;
                transform-origin: 100% 0;
            }
        }
    }
    .title-page {
        color: $blueblack;
        line-height: 42px;
        margin-bottom: 12px;
        padding-bottom: 20px;
        position: relative;
        padding-left: 25px;
        padding-right: 25px;
        @include tablet-down {
            padding-left: 15px;
            padding-right: 15px;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            width: 24px;
            height: 3px;
            background: $blueblack;
        }
        a {
            color: $blueblack;
            transition: all .3s ease;
            &:hover {
                text-decoration: none;
                opacity: .7;
            }
        }
    }
    p {
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 5px;
        @include tablet-down {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .date {
        color: $purple;
        margin-top: 25px;
        @include tablet-up {
            margin-top: 45px;
        }
    }
    .overlay-events {
        position: absolute;
        background: rgba($purple, .7);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .3s ease;
        opacity: 0;
        z-index: 1;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            content: "EXPLORE";
            font: 600 1rem/ normal $font-br;
            color: #fff;
        }
    }
}
//***************//
//EVENTS SINGLE//
//*************//
.banner-events {
    padding: 170px 0 105px;
    @include tablet-down {
        padding: 150px 0 70px;
    }
    .date-small {
        font: 600 14px/ 18px $font-Rr;
        letter-spacing: 0.35px;
        color: #A7AAAA;
        margin-bottom: 10px;
    }
    .date {
        color: $purple;
        padding-bottom: 30px;
    }
    .container {
        position: relative;
        @include max-width-center(850px);
    }
    .img-drop {
        padding-top: 100%/(1560/600);
        margin-bottom: 50px;
        @include tablet-down {
            margin-bottom: 25px;
        }
    }
    .events-content {
        padding-bottom: 30px;
        p {
            line-height: 24px;
        }
    }
    .btn-next-event {
        position: absolute;
        bottom: -105px;
        right: 15px;
        transform: translate3d(0, 50%, 0);
        @include tablet-down {
            bottom: -70px;
        }
    }
    .event-form {
        padding-top: 20px;
        .form-style {
            input[type="text"],
            input[type="email"],
            input[type="phone"] {
                background-color: #FFF;
            }
        }
    }
}
.title-events {
    font: 700 52px/60px $font-br;
    letter-spacing: 1.3px;
    color: $blueblack;
    text-transform: uppercase;
    @include tablet-down {
        font-size: 36px;
        line-height: 44px;
    }
}
.events-table {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    @include tablet-up {
        margin: 0 -7%;
    }
    @include desktop-up {
        margin: 0 -10%;
    }
    &>li {
        display: flex;
        margin-bottom: 0;
        background: #fff;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        @include mobile-down {
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @include small-mobile {
            justify-content: center;
        }
        &>div {
            padding: 15px 40px;
            @include mobile {
                width: 240px !important;
            }
            @include small-mobile {
                padding: 10px 20px;
                text-align: center;
            }
        }
        a.btn-edit.gradient {
            margin-left: auto;
            padding-top: 17px;
            padding-bottom: 17px;
            @include small-mobile {
                margin-left: 0;
            }
        }
    }
    .events-date {
        color: #333;
        width: 230px;
    }
    .events-time {
        color: #333;
        width: 180px;
    }
    .events-name {
        color: $blueblack;
        font-family: $font-br;
        text-transform: uppercase;
        letter-spacing: 0.4px;
        width: 400px;
    }
    @include mobile-down {
        .events-date {
            order: 2;
        }
        .events-time {
            order: 3;
        }
        .events-name {
            order: 1;
        }
        a.btn-edit.gradient {
            order: 4;
        }
    }
}
.facetwp-checkbox {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #333;
        width: 16px;
        height: 16px;
    }
}
.facetwp-facet-events_categories {
    .facetwp-checkbox {
        position: relative;
        background: none !important;
        padding-left: 30px !important;
        &.checked {
            &:after {
                transform: scale(1) rotate(125deg);
                opacity: 1;
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 2px;
            -webkit-transform: scale(1.3) rotate(125deg);
            transform: scale(1.3) rotate(125deg);
            width: 18px;
            height: 8px;
            border-top: 1px solid #49286f;
            border-right: 1px solid #49286f;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            opacity: 0;
        }
        
    }
}
    